.about_container {
  // background:#1A1E21;
  background: #202529 !important;
}

.masthead {
  height: 100vh;
  padding-bottom: -37px;
  // height: unquote("-webkit-calc(100vh - 37px)");
  // height: unquote("-moz-calc(100vh - 37px)");
  // height: unquote("-o-calc(100vh - 37px)");
  // height: unquote("calc(100vh - 37px)");
  // min-height: 650px;
  padding-top: 0px!important;
  padding-bottom: 0px;
  background-image: url(/assets/images/aboutUsMastHead.jpg);
  background-position: center center;
  background-size: cover;
}

.whatwedo_masthead {
  height: 100vh;
  padding-bottom: -37px;
  // height: unquote("-webkit-calc(100vh - 37px)");
  // height: unquote("-moz-calc(100vh - 37px)");
  // height: unquote("-o-calc(100vh - 37px)");
  // height: unquote("calc(100vh - 37px)");
  // min-height: 650px;
  padding-top: 0px!important;
  padding-bottom: 0px;
  // background-image: url(/assets/images/whatwedo.jpg);
  background-position: center center;
  background-size: cover;
}
.integration-masthead {
  height: unquote("-webkit-calc(100vh - 37px)");
  height: unquote("-moz-calc(100vh - 37px)");
  height: unquote("-o-calc(100vh - 37px)");
  height: unquote("calc(100vh - 37px)");
  // min-height: 650px;
  padding-top: 0px!important;
  padding-bottom: 0px;
  background-image: url(/assets/images/aboutUsMastHead.jpg);
  background-position: center center;
  background-size: cover;
}

.fetaures-icon {
  font-size: 60px;
  margin-bottom: 10px;
}

.team-masthead {
  height: 100vh;
  padding-bottom: -37px;
  // height: unquote("-webkit-calc(100vh - 37px)");
  // height: unquote("-moz-calc(100vh - 37px)");
  // height: unquote("-o-calc(100vh - 37px)");
  // height: unquote("calc(100vh - 37px)");
  // min-height: 650px;
  background-image: url(/assets/images/aboutUsMastHead.jpg);
  background-position: center center;
  background-size: cover;
  padding-top: 0px!important;
  padding-bottom: 0px;
}

.masthead-footer {
  height: 100vh;
  padding-bottom: -37px;
  // height: unquote("-webkit-calc(100vh - 75px)");
  // height: unquote("-moz-calc(100vh - 75px)");
  // height: unquote("-o-calc(100vh - 75px)");
  // height: unquote("calc(100vh - 75px)");
  // min-height: 650px;
  padding-top: 0px!important;
  padding-bottom: 0px;
  background-image: url(/assets/images/aboutUsMastHead.jpg);
  background-position: center center;
  background-size: cover;
}

.abt-txt h2, .team-txt h2 {
  color: #fff;
  text-transform: uppercase;
  font-weight: 500;
}

.btm-underline {
  border-bottom: 2px solid #f59d2c;
  width: 37%;
  margin: 0 auto;
}

.abt-txt p {
  color: #fff;
  margin: 0px 1% 0px;
  margin-bottom: 0 !important;
  font-size: 20px;
}

.text-faded {
  color: rgba(255,255,255,.7);
}

.about-link, .about-link:hover {
  color: #f59d2c;
}
