@mixin respond($breakpoint) {
    @if $breakpoint==phone {
        @media only screen and (max-width: 480px) {
            @content;
        } 
    }
    @if $breakpoint==tab {
        @media only screen and (max-width: 991px) and (max-height:600px){
            @content;
        }
        @media only screen and (max-width: 600px) and (max-height:991px){
            @content;
        }
          
    }

    @if $breakpoint==desktop {
        @media only screen and (max-width: 1200px) {
            @content;
        } 
    }
}