.switch-wrapper {
	.ant-switch {
		background-color: #4A4A4A;
		.ant-switch-inner {

		}
	}
	.ant-switch-small {
		min-width: 28px;
		height: 10px;
	}
	.ant-switch-checked {
		&:after {
	    width: 18px !important;
	    height: 18px !important;
	    top: -5px !important;
			left: 100% !important;
	    margin-left: 2px;
			border-radius: 50%;
			background-color: #56a9e8 !important;
		}
	}
	.ant-switch::after {
		position: absolute;
		top: -5px;
		left: -3px;
		width: 18px;
		height: 18px;
		background-color: #dbdbdb;
	}
}

@media only screen and (min-width: 1024px) and (max-width: 1280px) {
	.switch-wrapper {
		.ant-switch {
			.ant-switch-inner {

			}
		}
		.ant-switch-small {
			min-width: 28px;
			height: 8px;
		}
		.ant-switch-checked {
			&:after {
		    width: 16px !important;
		    height: 16px !important;
			}
		}
		.ant-switch::after {
			width: 16px;
			height: 16px;
		}
	}

}
