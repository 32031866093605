.magnifi-header-wrapper {
  // position: fixed;
  // top: 0;
  // z-index: 9;
  width: 100%;
  height: 60px !important;
  display: flex;
  // display: contents;
  align-items: center;
  line-height: normal !important;
  padding: 0 !important;
  background: inherit !important;
  // border-bottom: 1px solid #fff;
  // box-sizing: content-box !important;
  // -webkit-box-sizing: content-box !important;

  nav {
    width: 100%;
    height: 100%;
    border-bottom: 1px solid white;

    .container {
      margin: 0;
      width: 100%;
      max-width: 100%;
      padding-left: 0;
      padding-right: 0;
      margin-top: 4px !important;
      .navbar-brand {
        // box-sizing: content-box !important;
        // -webkit-box-sizing: content-box !important;
      }

      ul {
        li {
          i {
            font-size: 1.3rem;
          }
        }
      }
      // .searchInput {
      //   width: 33%;
      //   margin-left: -10%;
      //
      //   .search-box {
      //     border: solid 1px $border-color;
      //     input {
      //       width: 100%;
      //       height: 35px;
      //       padding-right: 40px;
      //       font-size: 16px;
      //       font-weight: normal;
      //       font-stretch: normal;
      //       font-style: normal;
      //       line-height: 1.35;
      //       letter-spacing: -0.24px;
      //       text-align: left;
      //       color: rgb(112, 112, 112);
      //     }
      //     .search-icon {
      //       right: 12px;
      //       font-size: 20px;
      //       font-weight: normal;
      //       font-stretch: normal;
      //       font-style: normal;
      //       line-height: 1.35;
      //       letter-spacing: -0.24px;
      //       text-align: left;
      //     }
      //   }
      // }
      // .search-box-onBoard {
      //   position: absolute;
      //   right: 164px;
      // }

      .portfolio-tools-wrap {
        // position: absolute;
        // right: 55px;
        width: 42%;
        min-width: max-content;
        max-width: 800px;
        max-width: 812px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        // .referral-link {
        //   font-weight: 700;
        //   color: #55a9e8;
        //   padding: 0;
        //   text-decoration: none;
        //   position: unset !important;
        //   margin-right: 20px;
        //   // right: 73px;
        //
        //   &:hover {
        //     color: #55a9e8;
        //     text-decoration: none;
        //   }
        // }
        .tools-txt {
          width: 100%;
          min-width: max-content;
          margin-right: 10px;
          padding: 7px 0;
          font-family: 'Open Sans';
          font-size: 1rem;
          font-weight: 800;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
        }
        .acc-page {
          margin-top: 1px;
        }
        // .tools-badge{
        //   background: white !important;
        //   border: 1px solid #5eade9;
        //   border-radius: 50px;
        //   background: transparent;
        //   margin-right: 10px !important;
        //   padding-top: 2px;
        //   .ant-badge-count{
        //     background: #5eade9;
        //   }
        // }
        .tool-badge.disabled-selector {
          border: 1px solid #dddddd !important;
          a {
            color: #9f9f9f !important;
          }
        }
        .tool-badge {
          width: 100%;
          max-width: 160px;
          margin-right: 10px !important;
          padding: 7px 17px;
          line-height: 32px;
          background: white !important;
          border: 1px solid #5eade9;
          border-radius: 50px;
          background: transparent;
          // padding: 0 10px;

          text-align: center;
          .ant-badge-count {
            top: 4px;
            right: 4px;
            background: #5eade9;
          }

          &.selected {
            a {
              color: white !important;
            }
            background-color: #1e5f91 !important;
            border: 1px solid #1e5f91 !important;
          }
          &:last-child {
            margin: 0 !important;
          }
        }
        .tools-link {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100% !important;
          max-width: 100% !important;
          height: 100%;
          padding-top: 0;
          padding-bottom: 0;
          padding: 0 2px;
          font-family: 'Open Sans';
          font-size: 1rem;
          font-weight: 800;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.2;
          color: #5eade9 !important;
          padding: 0;
          text-decoration: none;
          img {
            width: 24px;
            height: 24px;
            margin-right: 10px;
          }
          &:hover {
            color: #55a9e8;
            text-decoration: none;
          }
        }
      }
    }
    .user-menu-popover {
      // min-width: 270px !important;
      .navbar-brand {
        margin: 0;
        padding: 0;
      }
      .nav-link {
        i {
          font-size: 1.3rem;
        }
      }
    }
    .referral-link {
      font-weight: 700;
      color: $primary-color;
      padding: 0;
      text-decoration: none;
      // position: absolute;
      // right: 73px;

      &:hover {
        color: $primary-color;
        text-decoration: none;
      }
    }
  }
}

.client-portfolio-menu {
  // position: absolute;
  // right: 65px;
  margin-right: 10px;
  // border: solid 1px #5eade9;
  // border-radius: 50px;
  // background: #fff;
  .disabled-events {
    pointer-events: none !important;
  }
  .client-portfolio-menu-dropdown {
    .ant-dropdown-menu {
      .ant-dropdown-menu-item {
        position: relative;
        padding: 8px 15px;
        font-weight: 400;
        font-size: 16px;
        color: #202529;
        border-bottom: 1px solid rgba(205, 212, 219, 0.5);
        .menu-label {
          font-weight: bold;
          font-size: 16px;
          color: #5eade9 !important;
          text-decoration: none;
        }
        #uploadCustomPortfolio {
          position: absolute;
          left: 0;
          top: 0;
          z-index: 1;
          padding-right: 38px;
          opacity: 0;
        }
        .icon {
          width: 15px;
          height: 15px;
          vertical-align: 0;
        }
        .prefix-icon {
          margin-right: 10px;
          font-size: inherit;
          vertical-align: 0;
        }
        .suffix-icon {
          position: absolute;
          bottom: 13px;
          right: 15px;
          z-index: 9;
          margin: 0;
          vertical-align: 0;
        }
        &:last-child {
          border: 0;
        }
      }
    }
  }
  .client-portfolio-menu-active {
    color: #fff !important;
    background-color: #1e5f91 !important;
    border: 1px solid #1e5f91 !important;
  }
  .client-portfolio-menu-button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    padding: 7px 17px;
    line-height: normal;
    font-family: 'Open Sans';
    font-size: 1rem;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    color: #5eade9;
    border-color: #5eade9;
    border-radius: 50px;

    i {
      font-size: 21px;
      // position: absolute;
      // top: 6px;
      // left: 18px;
      // margin: 0 !important;
    }
    .anticon {
      // position: absolute;
      // right: 12px;
      // bottom: 5px;
      // margin: 0;
      // vertical-align: 0;
      line-height: 2;
    }
  }
}

@media only screen and (max-width: 1023px) and (min-width: 320px) {
  .client-portfolio-menu {
    display: none !important;
    .client-portfolio-menu-button {
      .button-label {
        // display: none !important;
      }
    }
  }
}

@media only screen and (max-width: 1280px) and (min-width: 1024px) {
  .portfolio-tools-wrap {
    position: unset !important;
    margin-right: 10px;
    align-items: center;

    .tools-txt {
      min-width: max-content !important;
      font-size: 0.65em !important;
    }
    .tool-badge {
      // height: 35px;
      width: 100px;
      padding: 5px 17px !important;
      .tools-link {
        color: transparent !important;
        font-size: 0.7em !important;
        img {
          width: 15px !important;
          height: 15px !important;
        }
      }
      &.selected {
        .tools-link {
          color: transparent !important;
        }
      }
    }
  }

  .client-portfolio-menu {
    // height: 35px !important;
    position: unset !important;
    margin-right: 10px !important;
    .client-portfolio-menu-button {
      height: 27px !important;
      position: relative;
      font-size: 0.7em !important;
      padding: 5px 17px !important;
      i {
        // top: 8px;
        // left: 14px !important;
        font-size: 1.5em !important;
      }
      .button-label {
        // display: none !important;
      }
      .anticon-down {
        // bottom: 6px !important;
        font-size: 0.8em !important;
      }
    }
  }
}

@media only screen and (max-width: 1439px) and (min-width: 1281px) {
  .portfolio-tools-wrap {
    // position: unset !important;
    margin-right: 10px;
    align-items: center;

    .tools-txt {
      min-width: max-content !important;
      // font-size: 0.65em !important;
    }
    .tool-badge {
      height: 35px;
      width: 100px;
      .tools-link {
        color: transparent !important;
        // font-size: 0.7em !important;
        img {
          width: 20px !important;
          height: 20px !important;
        }
      }
      &.selected {
        .tools-link {
          color: transparent !important;
        }
      }
    }
  }
  .client-portfolio-menu {
    height: 35px !important;
    // position: unset !important;
    margin-right: 10px !important;
    .client-portfolio-menu-button {
      height: 35px !important;
      position: relative;
      // font-size: 0.7em !important;
      i {
        // top: 8px;
        // left: 14px !important;
        // font-size: 1.7em !important;
      }
      .button-label {
        // display: none !important;
      }
      .anticon-down {
        // bottom: 6px !important;
        // font-size: 0.8em !important;
      }
    }
  }
}

@media only screen and (min-width: 1920px) {
  .client-portfolio-menu-button {
    height: 40px !important;
    i {
      // top: 9px !important;
    }
    .anticon {
      // bottom: 10px !important;
    }
  }
}

.brand-logo {
  margin-top: 25px;
  width: 115px!important;
}
