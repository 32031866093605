// source: https://codepen.io/GeoffreyCrofte/pen/BiHzp
/* Base for label styling */
.os-checkbox[type='checkbox']:not(:checked),
.os-checkbox[type='checkbox']:checked {
  position: absolute;
  left: -9999px;
}
.os-checkbox[type='checkbox']:not(:checked) + label,
.os-checkbox[type='checkbox']:checked + label {
  position: relative;
  padding-left: 1.95em;
  cursor: pointer;
}

/* checkbox aspect */
.os-checkbox[type='checkbox']:not(:checked) + label:before,
.os-checkbox[type='checkbox']:checked + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 1.25em;
  height: 1.25em;
  border: 2px solid #ccc;
  background: #fff;
  border-radius: 4px;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
}

.os-checkbox[type='checkbox']:checked + label:before {
  border: 2px solid $primary-color;
}

/* checked mark aspect */
.os-checkbox[type='checkbox']:not(:checked) + label:after,
.os-checkbox[type='checkbox']:checked + label:after {
  content: '\f00c';
  font-family: $font-awesome-pro;
  font-weight: $font-weight-bold;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  position: absolute;
  top: 0.15em;
  left: 0.22em;
  color: $primary-color;
  transition: all 0.2s;
}
/* checked mark aspect changes */
.os-checkbox[type='checkbox']:not(:checked) + label:after {
  opacity: 0;
  transform: scale(0);
}
.os-checkbox[type='checkbox']:checked + label:after {
  opacity: 1;
  transform: scale(1);
}
/* disabled checkbox */
.os-checkbox[type='checkbox']:disabled:not(:checked) + label:before,
.os-checkbox[type='checkbox']:disabled:checked + label:before {
  box-shadow: none;
  border-color: #bbb;
  background-color: #ddd;
}
.os-checkbox[type='checkbox']:disabled:checked + label:after {
  color: #999;
}
.os-checkbox[type='checkbox']:disabled + label {
  color: #aaa;
}
/* accessibility */
.os-checkbox[type='checkbox']:checked:focus + label:before,
.os-checkbox[type='checkbox']:not(:checked):focus + label:before {
  border: 2px solid $primary-color;
}

/* hover style just for information */
.os-checkbox[type='checkbox'] + label:hover:before {
  border: 2px solid $primary-color !important;
}
