.ssf-datepicker {
  display: flex;
  align-items: center;

  .react-datepicker-wrapper {
    width: 100%;
  }
  .react-datepicker__input-container {
    width: 100%;
  }
}

.ssf-datepicker__label {
  position: absolute;
  right: 21px;
}

.ssf-datepicker__calender-icon {
  font-size: 20px;
  color: #55a9e8;
}
