@import './variables';

#investments-tabs {
  color: $white;
  border-bottom: none;
  .nav-item {
    margin-bottom: 0px;
  }
  .nav-link {
    border: 2px solid $ssf-gray-l8;
    border-bottom: none;
    border-radius: 4px 4px 0 0;
    padding: 6px 6px 2px !important;
    background-color: #e5e5e5;
    text-transform: uppercase;
    cursor: pointer;
    color: $white;
    color: $ssf-blue-d3;
    &.active {
      cursor: default;
      color: $white;
      background-color: $ssf-gray-l8;
      font-weight: 600;
    }
  }
  .nav-item:last-of-type {
    border-right: none;
  }
}
