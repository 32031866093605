.main-container {
  background: rgba(0, 0, 0, 0.7);
  // height: 100vh;
}

.main-container-fluid {
  min-height: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
}

.masthead-main {
  height: 100vh;
  padding-bottom: -37px;
  // height: unquote("-webkit-calc(100vh - 37px)");
  // height: unquote("-moz-calc(100vh - 37px)");
  // height: unquote("-o-calc(100vh - 37px)");
  // height: unquote("calc(100vh - 37px)");
  // min-height: 650px;
  padding-top: 0px !important;
  padding-bottom: 0px;
}

.main-container__logo {
  text-align: center;
  padding-bottom: 20px;
  padding-top: 100px;
}

.main-container__search {
  text-align: center;
  padding-bottom: 20px;
}

.main-container__search-input {
  height: 52px;
  width: 80%;
  margin-top: 100px;
  font-size: 24px;
}

.main-container__footer {
  // margin-top: 29px;
  // margin-bottom: 30px;
  margin-top: 0px;
  margin-bottom: 0px;
  color: #202529;
}

.dropdown-menu {
  border-radius: 10px !important;
}

// .navbar-default .navbar-nav > li.dropdown:hover > a,
// .navbar-default .navbar-nav > li.dropdown:hover > a:hover,
// .navbar-default .navbar-nav > li.dropdown:hover > a:focus {
//     background-color: rgb(231, 231, 231);
//     color: rgb(85, 85, 85);
// }
// li.dropdown:hover > .dropdown-menu {
//     display: block;
// }

// .dropdown-menu{
//   background-color: #6c757d;
//     border-color: #6c757d;
// }

.mainMastHeadVerticalCenter {
  margin: auto;
}

.footer-link {
  color: #6c757d;
  font-family: Roboto, sans-serif !important;
}

.footer-link:hover {
  color: #6c757d;
}

.main-container__participate {
  text-align: center;
  font-family: Roboto, sans-serif !important;
  padding-top: 1.5rem !important;
  margin-top: 3rem !important;
}

.main-container__btn-participate {
  text-transform: uppercase;
  // border: 2px solid #A7A9AC;
  border: 2px solid #f59d2c;
  padding: 5px 20px;
  font-size: 20px;
  // color: #A7A9AC;
  color: #fff;
  border-radius: 20px;
  border-radius: 25px;
  font-weight: 500;
  outline: none !important;
  box-shadow: none !important;
}

.main-container__btn-participate:hover {
  color: #212529;
  background: #a7a9ac;
  outline: none !important;
  box-shadow: none !important;
}

.main-container__btn-participate:focus {
  outline: none !important;
  box-shadow: none !important;
}

#main__fp-nav {
  opacity: 1;
  margin-top: -32px;
  top: 50%;
  position: fixed;
  z-index: 100;
}

#main__fp-nav.fp-right {
  right: 17px;
}

#main__fp-nav ul,
.fp-slidesNav ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

#main__fp-nav ul li,
.fp-slidesNav ul li {
  display: block;
  width: 14px;
  height: 13px;
  margin: 7px;
  position: relative;
}

.fp-sr-only {
  width: 1px;
  height: 1px;
  padding: 0;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

#main__fp-nav ul li a span,
.fp-slidesNav ul li a span {
  border-radius: 50%;
  position: absolute;
  z-index: 1;
  height: 4px;
  width: 4px;
  border: 0;
  background: #333;
  cursor: pointer;
  left: 50%;
  top: 50%;
  margin: -2px 0 0 -2px;
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}

#main__fp-nav ul li a span,
#menu-line,
.fp-slidesNav ul li a span {
  background: #fff;
}

#main__fp-nav ul li a.active span,
#main__fp-nav ul li:hover a.active span,
.fp-slidesNav ul li a.active span,
.fp-slidesNav ul li:hover a.active span {
  height: 12px;
  width: 12px;
  margin: -6px 0 0 -6px;
  border-radius: 100%;
}

#main__fp-nav ul li .fp-tooltip.fp-right {
  right: 20px;
}

#main__fp-nav ul li .fp-tooltip {
  top: -2px;
  color: #fff;
  font-size: 14px;
  font-family: arial, helvetica, sans-serif;
  max-width: 220px;
  display: block;
  opacity: 0;
  cursor: pointer;
  width: 0;
}

#main__fp-nav ul li .fp-tooltip,
.fp-sr-only {
  position: absolute;
  overflow: hidden;
  white-space: nowrap;
}

#themeChart {
  padding-top: 10px !important;
}

.truncate {
  // width: 100%;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.details-link {
  text-decoration: underline !important;
  cursor: pointer;
}

.confirmation-detail-text {
  font-weight: 400;
}

.app-card {
  border-radius: 1rem;
}

.app-card-left {
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
}

.app-card-right {
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
}

.text-blue {
  color: #1b8be0;
}

.ticker-price {
  color: #4f5357;
}

.ticker-size {
  font-size: 0.95rem !important;
}

.footer-fixed {
  position: fixed;
  bottom: 0px;
}

.font-weight-400 {
  font-weight: 400;
}

.justify-content-center {
  justify-content: center !important;
}

.d-flex-center {
  display: flex;
  align-items: center;
}

.d-flex-col-align-start {
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;
}

.search-related-wrapper {
  display: flex;
  font-size: 16px;
  .search-label {
    display: flex;
    align-items: center;
    font-size: inherit;
    min-width: 65px;
    .results-count-color {
      width: 100%;
      font-size: inherit;
      padding-right: 4px;
      padding-left: 15px;
      // font-weight: bold;
      // color: $accent-blue;
      span {
        // white-space: nowrap;
        // overflow: hidden;
        // text-overflow: ellipsis;
      }
    }
  }
  .searched-query {
    color: $accent-blue;
    padding: 5px 0px;
    font-size: inherit;
  }
  .searched-query-badge {
    color: $ssf-blue-d3;
    padding: 5px 15px;
    font-size: inherit;
    border-radius: 30px;
    background-color: #efefef;
  }
}

.badge-wrapper {
  display: flex;
  max-height: 80px;
  overflow: hidden;
  font-size: 16px;
  color: $ssf-blue-d3;
  // margin-bottom: 0px !important;

  .search-badge-node {
    display: flex;
    min-width: 65px;
    max-height: 80px;
    overflow: hidden;
    flex-wrap: wrap;
    font-size: 16px;
    color: inherit !important;
  }
  .search-label {
    min-width: 65px;
    flex-direction: row;
    padding: 5px 0;
    margin-right: 10px;
    margin-bottom: 10px;
    font-size: inherit;
    color: inherit !important;
    border-radius: 30px;
    // cursor: pointer;
    transition: all 0.3s;
  }
  .search-badge {
    min-width: max-content;
    flex-direction: row;
    padding: 5px 11px;
    margin-right: 10px;
    margin-bottom: 10px;
    font-size: inherit;
    color: inherit;
    border-radius: 30px;
    background-color: #efefef;
    color: $ssf-blue-d3;
    cursor: pointer;
    transition: all 0.3s;
    &:hover {
      color: #fff;
      background: $ssf-orange;
    }
  }

  .search-badge.selected {
    background: $ssf-orange;
    color: #fff;
  }
}

.confirm-info-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  max-width: max-content !important;
  margin: 0 !important;
  h6 {
    color: #fff;
    font-size: 10px !important;
    line-height: 1.2;
  }
}

.confirm-info-container-inner-right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: max-content !important;
}

.confirm-info-account-number {
  font-size: 10px !important;
}

.confirm-info-error {
  font-size: 11px !important;
}

.align-item-flex-start {
  align-items: flex-start !important;
}

.align-item-center {
  align-items: center !important;
}

.fs-11 {
  font-size: 11px !important;
}

.fs-20 {
  font-size: 20px !important;
}

.flipster {
  display: block;
  overflow-x: inherit;
  overflow-y: inherit;
  position: relative;
}

.flip-items {
  li {
    text-align: center;
    h6 {
    }
  }
}

.search-fees-return-stats-padding {
  width: 33.33%;
  padding: 15px 0px 22px;
  margin-right: 2px;
}

.search-risk-stats-padding {
  width: 33.33%;
  padding: 22px 0px;
}

.search-risk-stats-highlighting {
  // background: #efefef;
  // background: #e6e6e6;
  background: #dff1ff;
}

.fees-flag-color {
  // color: #1b8be0;
  color: $ssf-blue-d3;
  text-align: center;
}

.returns-flag-color {
  color: #3f7367;
  // color: $ssf-blue-d3;
  text-align: center;
}

.risk-flag-color {
  color: #a5685f;
  // color: $ssf-blue-d3;
  text-align: center;
}

.share-component {
  display: flex;
  align-items: center;

  &__wrapper {
    height: 25px;
    display: flex;
    justify-content: space-around;
    margin-left: 7px;
    border-radius: 40px;
    background: #fff;
  }

  &__item {
    height: 20px;
    width: 20px;
    // padding: 10px;
    margin: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    background-color: #efefef;
    border-radius: 50%;
    color: #4a4a4a;

    &:hover {
      background-color: $tag-blue;
      cursor: pointer;
      transition: all 0.3s;

      & .far {
        color: #fff;
        transform: scale(1.1);
      }

      & .fa-twitter {
        color: #fff;
        transform: scale(1.1);
      }
    }
  }

  &__tootlip {
    background-color: #fff;
  }
}

.share-component__tooltip {
  font-size: 19px;

  .tooltip-inner {
    background-color: #4a4a4a !important;
  }
  .arrow:before {
    border-bottom-color: #4a4a4a !important;
  }
}
.selector-btn-tooltip {
  font-size: 10px !important;
  .tooltip-inner {
    background-color: #6c6c6c !important;
  }
  .arrow:before {
    border-bottom-color: #4a4a4a !important;
  }
}
.fees-returns-risk-header-container {
  display: flex;
  padding: 0 5px;

  .FRR-Label {
    // position: relative;
    width: 33.33%;
    height: 22px;
    margin: 0 2px;
    padding: 5px;
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    line-height: 1;
    transition: all 0.3s;
  }
  .FRR_space {
    // padding-left: 0px;
    // padding-right: 0;
    text-align: left;
    display: flex;
    justify-content: center;
  }
  .highlight {
    background: #4a4a4a;
    color: #fff;
    border-radius: 15px;
    transition: all 0.3s;
    .sort-options-wrap {
      .active {
        color: #fff !important;
      }
      .sort-options-checkbox {
        i {
          line-height: 1;
          color: #fff !important;
        }
      }
    }
  }
  .sort-options-wrap {
    position: relative;
    top: -4px;
    right: -2px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 16px;
    margin: 0;
    font-weight: 400;
    text-align: center;
    .active {
      color: #4a4a4a !important;
    }

    .sort-options-checkbox {
      margin: 0;
      height: 8px;
      font-size: unset !important;

      span {
        padding: 0 !important;
        font-size: unset !important;
      }
      i {
        // font-size: 15px !important;
        line-height: 1;
      }
      .ant-checkbox {
        display: none !important;
      }
      .ant-checkbox-checked {
        .sort-options-tooltip {

        }
      }
    }
  }
}

.display-flex-col {
  display: flex;
  flex-direction: column;
}
.display-flex-row {
  display: flex;
  flex-direction: row;
  .flex-row-child {
    padding: 2px;
  }
}
.align-items-center {
  align-items: center;
}
.padding-5 {
  padding: 5px !important;
}
.padding-5-left-1 {
  padding: 5px 5px 5px 1px !important;
}
.padding-5-right-1 {
  padding: 5px 1px 5px 5px !important;
}

.col-80 {
  width: 80%;
}
.col-50 {
  width: 50%;
}
.col-40 {
  width: 40%;
}
.col-25 {
  width: 25%;
}
.col-20 {
  width: 20%;
}

.broker-link {
  font-size: 0.875rem !important;
  color: #56a9e8;
  font-family: 'Roboto', sans-serif !important;
}

.item-card-area {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  height: calc(100% - 80px);

  .add-broker-wrapper {
    padding: 5px;
    margin-left: 4px !important;
    margin-right: 0 !important;
    max-width: 19.7%;
    .add-broker-button {
      margin: 0;
      padding: 5px 10px;
      font-size: 1rem;
      font-weight: bold;
      text-transform: uppercase;
      i {
        margin-right: 5px;
      }
    }
  }
}
.broker-carousel-wrapper {
  width: 100%;
  .slider-wrapper {
    width: 80%;
    ul {
      background: transparent !important;
      &:before {
        display: none !important;
      }
      li {
        display: flex;
        align-items: center;
        justify-content: center;
        background: transparent !important;
      }
    }
  }
  .carousel.carousel-slider .control-arrow {
    width: 15px !important;
    padding: 0 !important;
    background: transparent !important;
  }
  .carousel .control-prev {
    left: -5px !important;
  }
  .carousel .control-next.control-arrow:before {
    border-left: 8px solid black !important;
  }
  .carousel .control-prev.control-arrow:before {
    border-right: 8px solid black !important;
  }
  .carousel-status {
    display: none !important;
  }
}
.broker-label-wrapper {
  width: 80%;
  margin: 0 auto;
  transition: all 0.3s;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  .broker-label-bg {
    width: 100%;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    color: #fff;
    font-weight: 500;
    line-height: 1;
  }
  .dropDownText {
    font-size: 12px;
    line-height: 1.5;
    .btn-group {
      button {
        margin: 0 !important;
      }
    }
  }
}

.custom-carousel-container {
  position: relative;
  .carousel-nav-icon {
    .next {
      right: 0;
    }
    .previous {
      left: 0;
    }
    i {
      position: absolute;
      top: 0;
      bottom: 0;
      height: 44px;
      display: flex;
      align-items: center;
      cursor: pointer;
    }
    .selected {
    }
    .prevent {
      pointer-events: none !important;
    }
    .disabled {
      pointer-events: none !important;
      cursor: not-allowed;
      color: #b1b1b1;
    }
  }
  .custome-carousel-body {
    transition: all 0.3s;
  }
}

.d3ChartWrapper {
  position: relative;

  .primaryTextStyle {
    position: absolute;
    top: 50%;
    left: 51%;
    z-index: 9;
    margin: 0 auto;
    text-align: center;
    line-height: 1;
    font-size: 11px;
    transform: translate(-50%, -50%);
    // pointer-events: none;

    & .highlightTooltip {
      display: none;
      position: absolute;
      top: 15px;
      left: 10px;
      border: 1px solid #363a3e;
      width: 92px;
      font-weight: normal;
      padding: 4px 3px;
      font-size: 11px;
      background-color: #fff;
      box-shadow: 4px 3px 3px #888;
    }

    &:hover .highlightTooltip {
      display: block;
    }
  }
  .secondaryTextStyle {
    position: absolute;
    top: 52%;
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
    line-height: 1;
    font-size: 9px;
    pointer-events: none;
  }
}

.simplePieChartWrapper {
  position: relative;
  .primaryTextStyle {
    position: absolute;
    top: 50%;
    left: 50%;
    // left: 54%;
    z-index: 9;
    transform: translate(-50%, -50%);
    margin: 0 auto;
    text-align: center;
    line-height: 1;
    font-size: 11px;

    & .highlightTooltip {
      display: none;
      position: absolute;
      top: 15px;
      left: 10px;
      border: 1px solid #363a3e;
      width: 92px;
      font-weight: normal;
      padding: 4px 3px;
      font-size: 11px;
      background-color: #fff;
      box-shadow: 4px 3px 3px #888;
    }

    &:hover .highlightTooltip {
      display: block;
    }
  }
  .secondaryTextStyle {
    position: absolute;
    top: 52%;
    left: 4.5px;
    right: 0;
    margin: 0 auto;
    text-align: center;
    line-height: 1;
    font-size: 9px;
  }
}

.fz-9 {
  font-size: 11px !important;
}

.view-sub-title-container {
  min-height: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.view-text-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 5px;
  // padding: 0 32px;
  margin-bottom: 2px;

  .view-text-label {
    width: max-content;
    // margin: 0 5px;
    padding: 5px 9px;
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    line-height: 1;
    background: #ced4da;
    border-radius: 15px;
    cursor: pointer;
    &:hover {
      background: $ssf-orange-d1;
      color: #fff;
      transition: all 0.3s;
    }
  }
  .selected {
    background: #4a4a4a !important;
    cursor: default !important;
    color: #fff;
    border-radius: 15px;
  }
}

.embed-section {
  position: relative;
  display: flex;
  flex-direction: row;

  & .embed-head {
    font-weight: 500;
    font-size: 16px;
  }

  &__preview {
    padding: 5px;
    flex: auto;

    & .preview-section {
      & img {
        width: 100%;
      }
    }
  }

  &__code {
    display: flex;
    flex-direction: column;
    flex: 50% 1;
    padding: 5px;
    overflow: hidden;
    font-size: 0.8rem;

    & .code-section {
      &__text {
        padding: 5px;
        resize: none;
        min-height: 175px;
        width: 100%;
        font-family: 'Courier';
        font-size: 14px;
      }
    }

    & .text-section {
      margin-top: 10px;
      font-family: 'Roboto';
      font-size: 12px;
    }
  }
}

.backdropClassName {
  display: block !important;
}

.notify-card {
  position: fixed;
  bottom: 25px;
  left: 25px;
  background-color: #363a3e;
  color: #fff;
  padding: 5px 20px;
  border-radius: 4px;
  z-index: 1054;
  animation: notify 0.3s ease-in-out;
}

.namePop {
  border-radius: 0.4rem !important;
  z-index: 1030;

  @media (min-width: 1670px) {
    // left: -195px !important;
  }

  @media (max-width: 1670px) {
    // left: 0 !important;
  }
}

.pop-wrapper {
  padding: 0 10px 0 10px;
  margin: 0 -10px 0 -10px;
  background: transparent;
  .popover-close {
    display: none;
  }
}

.popover-drop {
  position: relative;

  & .fees-pop-tog {
    &:hover {
      cursor: default;
    }
  }

  &__toggle {
    // background-color: #dbdbdb;
    // border-radius: .5rem;
    padding: 2px 5px 2px 0;
    margin-bottom: 2px;
    // text-decoration: underline;

    &:hover {
      cursor: pointer;
    }
  }

  &__item {
    display: none;
    position: absolute;
    z-index: 100;
    margin-top: -2px;
    padding-top: 2px;
    background: transparent;

    &__wrapper {
      position: relative;
      width: 100%;
      list-style: none;
      border-radius: 0.5rem;
      background-color: #dbdbdb;
      // font-size: 12px;
      padding: 0;

      &__list {
        display: inline-block;
        padding: 4px 7px;
        min-width: 145px;

        &:hover {
          cursor: pointer;
          // background-color: #f8f9fa;
        }
      }
    }
  }
}

.cumulative,
.drawdown {
  display: none;
}

.br-1 {
  border-radius: 0 0 1rem 1rem;
}

.br-2 {
  border-radius: 1rem;
}

.shw-container {
  position: relative;

  & .show-less {
    width: 100%;
    z-index: -1;
  }

  & #btn-white {
    background-color: #fff !important;
  }

  & .shw-ls-btn {
    position: absolute;
    bottom: 0;
    right: 0;
    background: none !important;
    background-color: #f8f9fa !important;
    border: none;
    padding: 0 !important;
    color: $tag-blue;
    cursor: pointer;
    z-index: 10;
    font-size: inherit;
  }
}

.btn-white {
  & .show-less + .shw-ls-btn {
    background-color: #fff !important;
  }
}

.popover.broker-popover {
  min-width: unset !important;
}

.br-radius {
  &:last-child {
    border-radius: 0 0 1rem 1rem;
  }
}

.br-15px {
  border-radius: 15px;
}

.sponsor-logo-placeholder {
  width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
}

.notapp-status {
  display: flex;
  flex-direction: column;
  font-size: 13px;
  color: #979797;

  & img {
    // width: 32px;
    // margin-right: 2px;
  }

  &__text {
    margin: 0 auto;
  }
}
#referral-code {
  color: #56a9e8;
  opacity: 1;
  background: transparent;
}
.responsive-linechart {
  min-width: 100px;
  min-height: 100px;
}
