.conatct_container {
    // background:#1A1E21;
  }

  .contactMapContainer{
    border:"0";
    height: unquote("-webkit-calc(100vh - 37px)");
    height: unquote("-moz-calc(100vh - 37px)");
    height: unquote("-o-calc(100vh - 37px)");
    height: unquote("calc(100vh - 37px)");
  }

  .contact-masthead {
    height: 100vh;
    // height: unquote("-webkit-calc(100vh - 37px)");
    // height: unquote("-moz-calc(100vh - 37px)");
    // height: unquote("-o-calc(100vh - 37px)");
    // height: unquote("calc(100vh - 37px)");
    // min-height: 650px;
    padding-top: 0px!important;
    padding-bottom: 0px;
    background:#202529;
  }

  .contact-address-container{
    // height: unquote("-webkit-calc(100vh - 37px)");
    // height: unquote("-moz-calc(100vh - 37px)");
    // height: unquote("-o-calc(100vh - 37px)");
    // height: unquote("calc(100vh - 37px)");
    // padding-top: 30px;
    // margin: auto 0;
  }

  .btn-contact-same-width {
    width: 196px;
  }

  .abt-txt h2, .team-txt h2 {
    color: #fff;
    text-transform: uppercase;
    font-weight: 500;
  }

  .btm-underline {
    border-bottom: 2px solid #f59d2c;
    width: 37%;
    margin: 0 auto;
  }

  .abt-txt p {
    color: #fff;
    margin: 0px 1% 0px;
    margin-bottom: 0 !important;
    font-size: 20px;
  }

  .text-faded {
    color: rgba(255,255,255,.7);
  }

  .contactUpperRow {
    height: 40%;
  }
  .contactBottomRow {
    // height: auto !important;
    height: 60%;
    padding: 3rem 0;
  }
  .contact-address-container{
    min-height: 280px;
    margin: auto 0;
  }

  #map {
    width: 100vw;
  }
