$gutter: 15px;

* {
  margin: 0px;
  padding: 0px;
  outline: none;
  box-sizing: border-box;
}

a,
a:hover {
  text-decoration: none;
  cursor: pointer;
  outline: none;
  color: #222222;
}
p,
label {
  margin: 0px;
}
img {
  max-width: 100%;
}

body {
  font-family: 'Open Sans', sans-serif;
  /*font-family: 'Open Sans', sans-serif;*/
  background-attachment: fixed;
  background-size: cover;
  color: #585b5c;
  background: #f7f7f7;
  font-size: 11px;
  color: #4a4a4a;
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: 'Open Sans', sans-serif;
  margin: 0px;
}
label {
  font-weight: bold;
}
header {
  display: flex;
  padding: 10px 0px;
  > div {
    flex: 1;
  }
  .navbar-brand {
    max-width: 140px;
    padding: 0px;
    margin-bottom: 25px;
    margin-left: 1rem;

    img.logo {
      height: 40px;
    }
  }
  .header-middle {
    .trading-button {
      background: none;
      border-radius: 20px;
      -webkit-border-radius: 20px;
      float: right;
      display: inline-block;
      font-size: 0.9rem;
      color: #1e5f91;
      font-weight: bold;
      height: 36px;
      padding: 0px 15px;
      display: flex;
      align-items: center;
      letter-spacing: 0.5px;
      border: 1px solid #1e5f91;
      margin-left: 10px;

      &.current-selected,
      &:hover {
        background: #1e5f91;
        color: #ffffff;
      }
    }
  }
  .profile-anchor {
    text-align: right;
    max-width: 71px;

    span.user-menu__name-badge {
      width: 36px;
      height: 36px;
      background: #cdd4db;
      border-radius: 50%;
      margin: 0 auto;
      padding: 10px 2px;
      text-align: center;
      display: inline-block;
      font-weight: bold;
      font-size: 0.9rem;
      color: #222222;
    }
  }
}
.container-wrapper {
  margin: 40px auto auto;
  padding: 0 $gutter;
  min-height: 40px;
  width: 1400px;
  max-width: 100%;
}
.back-button {
  background: #dbdbdb;
  font-size: 0.7rem;
  padding: 0.3rem 1rem;
  border-radius: 13px;
  margin-bottom: 10px;
  display: inline-block;
  height: 1.6rem;
  color: #222222;

  i,
  svg {
    font-size: 10px;
    display: inline-block;
    vertical-align: top;
    margin-top: 4px;
  }
}
.blue-btn {
  background: #5fb0ea;
  font-size: 0.7rem;
  padding: 0px 0.5rem;
  border-radius: 13px;
  margin-bottom: 10px;
  display: inline-block;
  height: 1.4rem;
  line-height: 1.3rem;
  text-align: center;
  border: none;
  color: #ffffff;

  i,
  svg {
    font-size: 10px;
    display: inline-block;
    vertical-align: top;
    margin-top: 0.35rem;
  }
}
.line-btn {
  background: #ffffff;
  font-size: 0.7rem;
  padding: 0;
  border-radius: 13px;
  margin-bottom: 10px;
  display: inline-block;
  height: 1.4rem;
  border: 1px solid #dbdbdb;
  line-height: 1.3rem;
  text-align: center;

  i,
  svg {
    font-size: 10px;
    display: inline-block;
    vertical-align: top;
    margin-top: 0.35rem;
  }

  &.green-btn {
    border: 1px solid #92ada6;
    color: #92ada6;

    i,
    svg {
      color: #92ada6;
    }

    &:hover {
      background: #92ada6;
      color: #ffffff;

      svg,
      i {
        color: #ffffff;
      }
    }
  }
  &.red-btn {
    border: 1px solid #b28078;
    color: #b28078;

    i,
    svg {
      color: #b28078;
    }

    &:hover {
      background: #b28078;
      color: #ffffff;

      svg,
      i {
        color: #ffffff;
      }
    }
  }
}
.content-box {
  display: block;
  border: 1px solid #dddddd;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  overflow-x: scroll;

  .row-padding {
    padding: 10px 0px;
    margin: 0px;
  }
  .section-detail {
    padding: 10px 15px;
    width: auto;
  }
}
.hyper-link {
  color: #5fb0ea !important;
  font-weight: bold;
}
.rejected {
  color: #b68a83 !important;
}
.approved {
  color: #92ada6 !important;
}
.listing-search {
  width: 100%;
  display: block;
  height: 1.6rem;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  border: 1px solid #5fb0ea;
  background: #ffffff;
  padding-right: 30px;
  position: relative;

  input {
    width: 100%;
    height: 1.4rem;
    border: none;
    color: #5fb0ea;
    font-size: 0.7rem;
    border-radius: 20px;
    padding: 0px 10px;
  }
  .search-icon {
    width: 18px;
    height: 18px;
    position: absolute;
    right: 8px;
    top: 12px;
    border: 1px solid #5fb0ea;
    border-radius: 50px;
    -webkit-border-radius: 50px;
    font-size: 9px;
    text-align: center;
    color: #5fb0ea;
    padding: 1px;
    opacity: 1;
  }
  .cancel-icon {
    width: 18px;
    height: 18px;
    position: absolute;
    right: 30px;
    top: 3px;
    border: 1px solid #5fb0ea;
    border-radius: 50px;
    -webkit-border-radius: 50px;
    font-size: 9px;
    text-align: center;
    color: #5fb0ea;
    padding: 1px;
    opacity: 1;
  }
}
.table-dot {
  width: 5px;
  height: 5px;
  background: #5fb0ea;
  position: absolute;
  right: 5px;
  top: 5px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
}

.custom-table {
  .table-head.table-row {
    background: #efefef;

    .table-col {
      color: #222222;
      font-weight: bold;
    }
  }
  .table-row {
    border-radius: 5px;
    border: 1px solid #aaaaaa;
    display: flex;
    justify-content: center;
    margin-bottom: 1px;

    border: 1px solid #dddddd;
    .table-col {
      padding: 0px;
      flex: 1;
      text-align: center;
      padding: 8px 10px;

      &:first-child {
        border-radius: 5px 0px 0px 5px;
        -webkit-border-radius: 5px 0px 0px 5px;
      }
      &:last-child {
        border-radius: 0px 5px 5px 0px;
        -webkit-border-radius: 0px 5px 5px 0px;
      }
    }
    .trade-row {
      overflow-x: hidden;
      max-width: 150px;
    }
  }
}

.basket-filter-menu {
  display: flex;
  height: 500px;
  width: 400px;
  flex-direction: column;

  .menu-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 10px;
  }
  .dropdown-menu-btn {
    width: 100px;
    margin: 10px 0px 0px 10px;
  }
}

.dropdown-menu-btn-reset {
  width: 100px;
  margin: 0px 0px 10px 30px;
  background: #f4f4f4;
}
.custom-table2 {
  .table-head.table-row {
    .table-col {
      color: #222222;
      font-weight: bold;
    }
  }
  .table-row {
    border-radius: 5px;
    border: none;
    display: flex;
    justify-content: center;
    margin-bottom: 1px;

    .table-col {
      padding: 0px;
      flex: 1;
      text-align: center;
      padding: 8px 0px;
      &:first-child {
        border-radius: 5px 0px 0px 5px;
        -webkit-border-radius: 5px 0px 0px 5px;
      }
      &:last-child {
        border-radius: 0px 5px 5px 0px;
        -webkit-border-radius: 0px 5px 5px 0px;
      }
    }
  }
}
.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
ul.custom-tabs {
  display: flex;
  list-style: none;
  border-bottom: 1px solid #dddddd;

  li {
    display: inline-block;
    padding: 1px 0px;
    margin-right: 3rem;
    a {
      padding: 3px;
      display: inline-block;
      vertical-align: top;
      position: relative;

      .tab-dot {
        width: 5px;
        height: 5px;
        background: #5fb0ea;
        position: absolute;
        right: -3px;
        top: 0px;
        border-radius: 10px;
        -webkit-border-radius: 10px;
      }

      &:hover,
      &.active {
        color: #5fb0ea;
        font-weight: bold;
        border-bottom: 2px solid #5fb0ea;
        margin-bottom: -2px;
      }
    }
  }
}
.applicant-label {
  color: #777777;
  font-size: 0.6rem;
}
.applicant-label-outer {
  justify-content: flex-end;
}
.upload-outer {
  margin-bottom: 20px;
  display: block;
  p {
    svg {
      color: #5fb0ea;
      margin-right: 1px;
      cursor: pointer;
    }
  }
  .upload-image {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 150px;
    background: #ffffff;
    position: relative;
    margin: 10px 0px;
    img {
      max-height: 100%;
    }
    a {
      position: absolute;
      right: 10px;
      bottom: 10px;
      z-index: 1;
      svg {
        color: #5fb0ea;
      }
    }
  }
}
.active-btn {
  min-width: 100px;
}
.relationship-row {
  padding: 10px 0px;
  border-bottom: 1px solid #dddddd;
  margin: 0px;
  overflow-x: scroll;

  > div {
    padding: 0px;
  }
}
.cancel-ach-btn {
  color: #56a9e8;
  position: absolute;
  right: -80px;
  font-size: 12px;
  cursor: pointer;
}
.cancelled {
  color: #777777 !important;
}

.hr-divider {
  margin-top: unset;
  margin-bottom: unset;
}

.filter-menu-section {
  text-align: center;
  font-family: 'Roboto';
  font-size: 14px;
  color: #66b3ea;
  font-weight: 500;
}

.filterMenuItem {
  text-align: center;
  cursor: pointer;
  font-size: 12px;
}
.popup-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
  display: flex;
  align-items: center;
  justify-content: center;

  .model-popup {
    &.md-popup {
      width: 600px;
    }

    position: relative;
    background-color: #fff;
    background-clip: padding-box;
    border: 0;
    border-radius: 2px;
    -webkit-box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12);
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12);
    pointer-events: auto;
    border-radius: 30px;
    width: 470px;
    transform-origin: 643.5px -7.5px;

    .popup-cross {
      position: absolute;
      right: 30px;
      top: 30px;
      color: #777777;

      svg,
      i {
        font-size: 20px;
      }
    }

    h1 {
      margin: 20px 0;
      font-size: 30px;
      color: #4a4a4a;
      text-align: center;
      font-size: 2rem !important;
      margin-top: 4rem;
      line-height: 2.5rem;
    }
    .model-body {
      padding: 1rem;
    }
  }
}

.popup-btn {
  background: #5fb0ea;
  font-size: 1rem;
  font-weight: 500;
  padding: 1rem 2rem;
  border-radius: 2rem;
  margin-bottom: 10px;
  display: inline-block;
  height: auto;
  line-height: 1.3rem;
  text-align: center;
  border: none;
  color: #ffffff;
  text-transform: uppercase;
  min-width: 8rem;

  &.line-btn {
    background: none;
    border: 1px solid #5fb0ea;
    color: #5fb0ea;
  }

  &.rejected {
    background: #b68a83;
    color: #ffffff !important;
  }
  &.rejected {
    background: #b68a83;
    color: #ffffff !important;
  }
  &.approved {
    background: #447569;
    color: #ffffff !important;
  }
  &.approved-line {
    border: 1px solid #447569;
    color: #447569;
    background: none;

    i,
    svg {
      color: #447569;
      font-size: 1.2rem;
      margin: 0px;
    }
  }
  &.rejected-line {
    border: 1px solid #b68a83;
    color: #b68a83;
    background: none;

    i,
    svg {
      color: #b68a83;
      font-size: 1.2rem;
      margin: 0px;
    }
  }

  i,
  svg {
    font-size: 10px;
    display: inline-block;
    vertical-align: top;
    margin-top: 0.35rem;
  }
}
.address-proof {
  max-height: 300px;
  margin-bottom: 30px;
  max-width: 100%;
}
.reject-document-textarea {
  padding: 0px 1rem;
  margin-bottom: 20px;

  textarea {
    resize: none;
    height: 15vh;
    border: 1px solid #dddddd;
    width: 100%;
  }
}
.document-row {
  padding: 10px !important;
  border-radius: 5px;
  margin-bottom: 6px;
  border-bottom: 1px solid #dddddd !important;
  font-size: 0.8rem;
  display: flex !important;
  align-items: center;

  div {
    display: flex;
    align-items: center;
    span {
      text-transform: capitalize;
    }
  }

  button {
    background: #1e5f91;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    float: right;
    display: inline-block;
    font-size: 0.8rem;
    color: #ffffff;
    font-weight: bold;
    height: 36px;
    padding: 0px 15px;
    display: flex;
    align-items: center;
    letter-spacing: 0.5px;
    border: none;
    margin-left: 5px;
  }
}

.request-tab {
  .text-left {
    position: relative;
    .action-container {
      position: absolute;
      top: 0;
      right: -90px;
      width: 90px;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      a {
        margin: 0 !important;
        border-radius: 14px;
        height: 28px !important;
        width: 28px !important;
        display: flex;
        align-items: center;
        justify-content: center;
        i {
          margin: 0 !important;
        }
      }
    }
  }
}

.reason-modal {
  .modal-content {
    border-radius: 20px;
  }
  .modal-header {
    border-bottom: none;
    .modal-title {
      font-size: 30px;
      color: #4a4a4a;
      text-align: center;
      font-size: 2rem !important;
      padding-bottom: 0px;
    }
  }
  .modal-footer {
    border-top: none;
  }
  .lined-btn {
    background: none !important;
    border: 1px solid #5fb0ea !important;
    color: #5fb0ea !important;
  }
  .blue-btn-footer {
    padding-top: 0px;
    background: #5fb0ea;
    font-size: 1rem;
    font-weight: 500;
    padding: 1rem 2rem;
    border-radius: 2rem;
    margin-bottom: 10px;
    display: inline-block;
    height: auto;
    line-height: 1.3rem;
    text-align: center;
    border: none;
    color: #ffffff;
    text-transform: uppercase;
    min-width: 8rem;
  }
}
.fileUploadOuter {
  float: left;
  width: 100%;
  text-align: left;
  padding-left: 17px;
  margin-bottom: 28px;
  position: relative;

  input {
    position: absolute;
    width: 166px;
    height: 50px;
    opacity: 0;
    z-index: 1;
    cursor: pointer;
  }
  .customFileUpload {
    width: auto;
    height: 50px;
    border: 1px solid #ddd;
    border-radius: 3px;
    background: #f5f5f5;
    padding: 14px;
    font-size: 15px;
    position: relative;
    display: inline-block;
    padding-right: 40px;

    .iconUpload {
      font-size: 20px;
      color: #777777;
      margin-right: 10px;
    }
    .iconCheck {
      font-size: 18px;
      color: #777777;
      margin-right: 10px;
      position: absolute;
      right: 0px;
      color: #447569;
      top: 17px;
    }
    .fileload {
      position: absolute;
      right: 9px;
      color: #447569;
      top: 17px;
      height: 19px;
    }
  }
}
