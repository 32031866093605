@media(max-width:1050px){

}
@media(max-width:991px){
	.container-wrapper{
		max-width: 90%;
	}
}
@media(max-width:767px){
	ul.custom-tabs {
    	display: block;

    	li {
		    display: block;
		    margin-right: 0px;

		    a{
		    	display:block;
		    }
		}
	}
	header .header-middle .trading-button{
		font-size:0.8rem;
	}
	.custom-table{
		min-width:600px;
	}
}
@media(max-width:540px){

}
