
.csv-preview-upload-popover {
  width: auto !important;
  min-width: 160px !important;
  z-index: 9999;
}

.row-has-error {
  background: #d8636345 !important;
  td {
    background: #d8636345 !important;
  }
}

.top-60px {
  top: 60px !important;
}

.col-has-error {
  position: relative;
  .error-message {
   position: absolute;
   bottom: -12px;
   left: 0px;
   width: 100%;
   font-size: 10px !important;
   overflow: hidden;
   line-height: 1;
   text-overflow: ellipsis;
 }
}

.ant-table-filter-dropdown {
  .ant-btn-primary {
    color: #fff !important;
    background: #1d6091 !important;
    border-color: #1d6091 !important;
  }
  .anticon {
    vertical-align: 0;
  }
  .ant-input {
    &:hover, &:active, &:focus {
      background: #fff;
      color: #1d6091;
      border-color: #1d6091;
      box-shadow: 0 0 0 2px rgba(29, 96, 145, 0.10);
    }
  }
  .ant-btn {
    background: #fff;
    color: #1d6091;
    border-color: #1d6091;
    &:hover {
      background: #fff;
      color: #1d6091;
      border-color: #1d6091;
    }
  }
}

.disclaimer-text {
  position: relative;
  font-family: 'Open Sans';
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: left;
  color: #c5c5c5;
  margin: 18px 0;
  padding-left: 8px;
  i {
    position: absolute;
    top: 1px;
    left: 0;
    font-size: 7px;
  }
}


.csv-preview-upload {
  top: 60px !important;
  .ant-modal {
    padding-top: 24px;
    padding-bottom: 24px;
    max-width: 600px;
  }
  .ant-modal-content {
    border-radius: 20px;
    .ant-modal-header {
      padding: 24px;
      border: 0;
      border-radius: 20px;
    }
    .ant-modal-title {
      border: 0;
      // border-radius: 30px;
    }
    .title-head {
      font-family: 'Roboto';
      font-size: 1.7rem !important;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.31;
      letter-spacing: normal;
      text-align: center;
      color: #1E5F91;
    }
    .ant-modal-body {
      padding-top: 0;
      // height: 675px;
      button {
        border-radius: 30px;
      }
    }
  }

  .cp-modal-close {
    font-size: 1.2rem
  }
}
.csv-preview-upload-container {

  .ant-table {
    .ant-table-column-sorter-up.active, .ant-table-column-sorter-down.active {
      color: #1d6091 !important;
    }
    .ant-form-item {
      .ant-form-item-control .ant-form-item-control-input-content .ant-input {
        height: 28px;
      }
      .ant-form-item-control-input {
        min-height: auto;
        position: relative;
      }
      .ant-form-item-explain {
        position: absolute;
        bottom: -12px;
        font-size: 10px;
        line-height: 1;
        min-height: auto;
      }

    }
    table {
      thead {
        tr {
          th {
            font-family: Roboto;
            font-size: 14px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.14;
            letter-spacing: normal;
            text-align: center;
            color: #1d6091;
            border: 0;
            background: transparent;
            .anticon-search {
              font-size: 14px;
            }
            &:first-child {
              text-align: left;
            }
          }
          &:first-child {
            th:last-child {
              text-align: center;
            }
          }
        }
      }
      tbody {
        tr {
          td, span {
            font-family: 'Open Sans';
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.36;
            letter-spacing: normal;
            text-align: center;
            color: #4a4a4a;
          }
          td {
            // border-bottom-color: #c5c5c5;
            &:first-child {
              text-align: left;
            }
            &:last-child {
              text-align: center;
              i {
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
  .ant-pagination {
    .ant-pagination-item, .ant-pagination-prev, .ant-pagination-next, .ant-pagination-jump-prev, .ant-pagination-jump-next {
      min-width: 22px;
      height: 22px;
      font-size: 12px;
      line-height: 20px;
      &:hover, &:active, &:focus {
        .ant-pagination-item-link {
          background: #fff;
          color: #1d6091;
          border-color: #1d6091;
          box-shadow: 0 0 0 2px rgba(29, 96, 145, 0.10);
        }
      }
    }
    .ant-pagination-item-link {
      // margin-top: -1px;
    }
    .anticon {
      vertical-align: 0.2em;
      font-size: 10px;
    }
    .ant-pagination-item-active {
      border-color: #1d6091 !important;
      a {
        color: #1d6091 !important;
      }
    }
  }
}

.invalid-ticker-table{
  padding-bottom: 15px;
  // .ant-table-wrapper{
  //   .ant-table{
  //     max-height: 110px;
  //     overflow: hidden;
  //   }
  //   &.show-all-invalid-tickers{
  //     .ant-table{
  //       height: 100%;
  //       max-height: 100%;
  //       overflow: auto;
  //     }
  //   }
  // }  
  .head-note{
    display: flex;
    justify-content: space-between;
    p{
      font-size: 16px;
      color: #1d6091;
      font-weight: 500;
      margin-bottom: 10px;
    }
    span{
      color: #ababab;
      text-decoration: underline;
      cursor: pointer;
    }
  }
  .error-desc{
    font-size: 12px;
    color: #b2716a;
  }
  .valid-desc{
    font-size: 12px;
    color: #00823a;
  }
  .foot-note{
    font-size: 12px;
    p{
      color: #b2716a;
      margin-top: 5px;
      margin-bottom: 0px;
    }
  }
  .edit-ticker{
      input[type="text"]{
        width: 100% !important;
        height: 20px;
        border: 1px solid #ccc;
        padding: 10px 6px;
      }
  }
}

.valid-ticker-table{  
  .ant-table-wrapper{
    .ant-table{
      min-height: 280px;
    }
  }
  .head-note{
    display: flex;
    p{
      font-size: 16px;
      color: #1d6091;
      font-weight: 500;
      margin-bottom: 10px;
    }
  }
}

.fx-clm-space-between{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.fx-clm{
  display: flex;
  flex-direction: column;
}

.editable-cell {
  position: relative;
}

.editable-cell-value-wrap {
  padding: 5px 0px !important;
  cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding: 4px 11px;
  .error-message {
    bottom: -14px !important;
  }
}

[data-theme='dark'] .editable-row:hover .editable-cell-value-wrap {
  border: 1px solid #434343;
}
