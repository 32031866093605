body {
  background: #F7F7F7 !important;
}
.text-tag-blue {
  color: $tag-blue !important;
}
.mg-rt-minus-1 {
  margin-right: -1px !important;
}
.wid-100 {
  width: 100%;
}
.pageContainer {
  background: #F7F7F7 !important;
  .topSectionWrapper {
    color: $charcoal-black !important;
    background: #F7F7F7 !important;
    .search-header-label {
      margin-bottom: -5px;
      margin-top: 20px;
    }
    .navBarWrapper {
      background: #F7F7F7 !important;
      box-shadow: unset !important;
      color: inherit !important;
      .container {
        padding-top: 10px;
        color: inherit !important;
        .logoWrapper {

        }
        .userMenuWrapper {
          .nav-link {
            color: #6c757d !important;
          }
        }
      }
    }
    .searchBoxWrapper {
      color: $charcoal-black !important;
      .search-box {
        // max-width: 50%;
        max-width: 740px;
        color: inherit !important;
        input {
          height: 36px;
          color: inherit !important;
          font-size: 15px;
          line-height: 1;
          font-weight: normal;
          border: 1px solid #bbbbbb;
        }
        .search-icon {
          right: .25rem;
          font-size: 17px;
          opacity: 0.8;
          text-align: center;
        }
      }
    }
    .tab-universe-container{
      margin-top: 0px;
      padding-left: 15px;

      .check-label {
        // right: -25px;
        width: auto;
      }

      .uni-tooltp {
        font-size: 12px;
        position: absolute;
        right: 32px;
        bottom: 1px;

        @media only screen and (max-device-width: 768px) {
          right: 0;
          bottom: 0px !important;
        }
        @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
          right: 25px;
          bottom: 0px;
        }
      }
      .share-icon-div{
        display: flex;
        position: absolute;
        right:  18px;
        margin-top: 2px;
      }
    }
    .badgeWrapper {
      color: inherit !important;
      .badge-wrapper {
        flex-direction: column;
        max-height: 122px;
        color: inherit !important;
        .search-label {
          margin-bottom: 5px;
          color: inherit !important;
        }
        .search-badge-node {
          font-size: 14px;
          .search-badge {
            padding: 8px 15px;
            color: $tag-blue;
            font-size: 12px;
            border: 1px solid $tag-blue;
            background: transparent;
            &:hover {
              background: $tag-blue;
              color: #fff;
            }
          }
        }
      }
      .search-related-wrapper {
        color: inherit !important;
        .searched-query {
          color: inherit !important;
        }
        .results-count-color {
          padding-left: 0;
          padding-right: 0;
          color: inherit !important;
          span {
            padding-right: 3px;
            color: inherit !important;
          }
        }
      }
    }
    .toolbarWrapper {
      background: #F7F7F7 !important;
      .display-flex {
        display: flex !important;
        flex-direction: column;
        position: absolute;
        z-index: 9;
        background: $filter-bg-color;
        border-radius: 2px;
        li {
          color: $charcoal-black;
          font-size: 12px;
          line-height: 1.5;
        }
      }
      .filterButtonWrapper {
        border-radius: 15px !important;
        background: $filter-bg-color;
        color: $charcoal-black !important;
        border: 0 !important;
        .filterbutton {
          position: relative;
          font-size: 12px;
          font-weight: 600;
          color: $charcoal-black !important;
          // background: $filter-bg-color;
          border-radius: 15px !important;
          border: 0 !important;
          &:after {
            opacity: 0;
            display: none;
          }
          .dropdown-toggle:focus {
            box-shadow: none;
          }
          .dropdown-menu {
            .select-col {
              background: #dff1ff;
            }
            .dropright {
              &:first-child {
                border-top-left-radius: 4px;
                border-top-right-radius: 4px;
              }
              &:last-child {
                border-bottom-left-radius: 4px;
                border-bottom-right-radius: 4px;
              }
              .dropdown-button {
                width: 100%;
                color: $charcoal-black;
                font-size: inherit;
                text-align: left;
                line-height: 1;
                border: 0;
                &:after {
                  margin-left: .255em;
                  content: "";
                  border-top: .3em solid transparent;
                  border-right: 0;
                  border-bottom: .3em solid transparent;
                  border-left: .3em solid;
                  float: right;
                  margin-top:3px;
                }
              }
              .select-col {
                background: #dff1ff;
              }
              .second-level-dropdown {
                min-width: 95px;
                margin: 0;
                padding: 0;
                padding-left: 2px;
                font-size: inherit;
                background: transparent;
                border: 0px;
                transform: translate3d(93px, 0px, 0px)!important;
                .dropdown-wrapper {
                  padding: .25rem 0;
                  // overflow: hidden;
                  background: $filter-bg-color;
                  border-radius: 10px;
                  border: 1px solid rgb(239, 239, 239);

                  .dropdown-item {
                    &:active, &:hover, &:focus, &:active {
                      color: $charcoal-black;
                      background-color: transparent;
                    }
                  }
                  .custom-control {
                    min-height: 16px !important;
                    padding-left: 18px;
                    line-height: 16px !important;
                    .custom-control-input {
                      left: 0;
                      width: 100%;
                      height: 100%;
                    }
                    .custom-control-input:checked ~ .custom-control-label::before {
                      border-color: $tag-blue;
                      background-color: transparent;
                    }
                    .custom-control-input:checked ~ .custom-control-label::after {
                      content: "\f00c";
                      font-family: "Font Awesome 5 Pro";
                      font-size: 8px;
                      text-align: center;
                      font-weight: bold;
                      color: $tag-blue;
                      background-image: unset;
                    }

                    .custom-control-label {
                      pointer-events: none;
                      &:before {
                        top: 1.1px;
                        left: -17px;
                        width: 14px;
                        height: 14px;
                        background-color: transparent;
                      }
                      &:after {
                        top: 0.5px;
                        left: -17px;
                        width: 14px;
                        height: 14px;
                      }
                    }
                  }
                }
              }
              &:focus {
                box-shadow: none;
              }
              &:active, &:hover {
                background-color: #f8f9fa;
                // color: #1b8be0;
              }
            }
          }
        }

      }
      .filterButtonWrapper.select-col {
        background: #dff1ff;
      }
      .unselect-col {
         .filterbutton {
          background: $filter-bg-color;
         }
      }
      .select-col {
         .filterbutton {
          background: #dff1ff;
         }
      }

      .sortButtonWrapper {
        border-radius: 15px !important;
        background: $filter-bg-color;
        color: $charcoal-black !important;
        border: 0 !important;
        .sortbutton {
          position: relative;
          font-size: 12px;
          font-weight: 600;
          color: $charcoal-black !important;
          background: $filter-bg-color;
          border-radius: 15px !important;
          border: 0 !important;
          &:after {
            opacity: 0;
            display: none;
          }
          .dropdown-toggle:focus {
            box-shadow: none;
          }
          .dropdown-item:active, .dropdown-item:hover {
            background-color: transparent;
            // color: #1b8be0;
          }
        }
        .dropdown-item {
          color: $charcoal-black;
          font-size: inherit;
        }
      }
      .ghost-wrapper {
        position: absolute;
        z-index: 9;
        min-width: 95px;
        display: flex !important;
        flex-direction: column;
        margin-left: 0;
        padding-top: 3px;
        font-size: inherit;
        background: transparent;
        border: 0px;
        .dropdown-wrapper {
          padding: .25rem 0;
          // overflow: hidden;
          background: $filter-bg-color;
          border-radius: 10px;
          border: 1px solid rgb(239, 239, 239);
          .dropdown-item {
            &:first-child {
              border-top-left-radius: 4px;
              border-top-right-radius: 4px;
            }
            &:last-child {
              border-bottom-left-radius: 4px;
              border-bottom-right-radius: 4px;
            }
            &:hover {
              background-color: #f8f9fa !important;
              // color: #1b8be0;
            }
          }
        }
      }
    }

    .selRangeButtonWrapper {
      border-radius: 15px !important;
      background: #fff;
      color: #494949 !important;
      border: 0 !important;
      font-size: 12px;
      height: 100%;

      &:hover {
        cursor: pointer;
      }

      .selrangebutton {
        padding: 6px 10px;
        position: relative;
        font-size: 12px;
        font-weight: 600;
        color: #494949 !important;
        background: #fff;
        border-radius: 15px !important;
        border: 0 !important;

        .btn:focus {
          outline: none;
          border: none;
          box-shadow: none;
        }
      }

      .br-btm {
        border-bottom: 15px solid #fff !important;
        border-radius: 15px 15px 0 0 !important;
      }
    }

    .selRangeCollapseWrapper{
      width: 100%;
      background: #fff !important;
      margin: 0 15px !important;
      padding: 10px !important;
      border-radius: 15px 0 15px 15px !important;
    }

    .selRangeDateWrapper {
      .row {
        margin: 0 auto;
        width: 90%;
      }

      .irs-line > * {
        background-color: #EFEFEF !important;
      }

      .irs-bar {
        background: #55a9e8;
      }

      .irs-slider {
        border: 4px solid #55a9e8 !important;
        background: #fff;
      }

      button {
        font-size: 14px;
      }
    }

    .cardListToolbarWrapper {
      background: #F7F7F7 !important;
      .viewDropdownContainer {
        .view-text-container {
          .view-text-label {
            position: relative;
            z-index: 9;
          }
        }
        .viewDropdownWrapper {
          width: 100%;
          .viewDropDownList {
            width: 100%;
            padding: 5px 0 !important;
            border: 0 !important;
            background: transparent;
            margin: 0;
            // pointer-events: none;
            .ghost-child {
              width: 37px;
              height: 30px;
              top: -25px;
              left: 0;
              right: 0;
              z-index: 9999;
              margin: 0 auto;
              position: absolute;
              cursor: pointer;
            }
            .viewDropDownList-Child {
              padding: 5px 0px;
              min-width: max-content;
              text-align: center;
              border: 1px solid rgba(0, 0, 0, 0.15);
              border-radius: 10px;
              background: #fff;
            }
          }
        }
        .viewBtn {
          width: 100%;
          margin: 0 !important;
          padding: 0 !important;
          border: 0 !important;
        }
      }
    }
  }

  .vehicle-label {
    // max-width: 37px;
    margin: 0 auto;
    line-height: 1.2;
    font-size: 10px;
    font-weight: 600;
    color: #57869c;
    text-align: center;
  }

  .media-vehicle-label {
    // margin: unset;
    // margin-top: 20px;
    // margin-right: -14px;
  }

  .pageWrapper {
    .itemCardWrapper {
      .border-transparent {
        border-color: transparent !important;
      }
      .border-bg-filter-bg-color {
        border-color: $filter-bg-color !important;
      }
      .itemCard {
        border-color: transparent;
        background: transparent !important;
        .card-background {
          background: #efefef !important;
        }
        .feesReturnRiskWrapper {
          .search-risk-stats-highlighting {
            .returns-flag-color {
              color: $tag-blue;
            }
            .fees-flag-color {
              color: $tag-blue;
            }
            .risk-flag-color {
              color: $tag-blue;
            }
          }
        }
        .paddingTpBtLt {
          padding-top: 0 !important;
          padding-bottom: 0 !important;
          padding-left: 0 !important;
        }
        .paddingTpBt {
          padding-top: 0 !important;
          padding-bottom: 0 !important;
        }
        .paddingTpBtRt {
          padding-top: 0 !important;
          padding-bottom: 0 !important;
          padding-right: 0 !important;
        }

        .selectBtn {
          padding: 5px;
          padding: 8px 15px !important;
          font-size: 12px !important;
          border-radius: 30px;
          line-height: 1.4;
          background: transparent;
          border-color: $ssf-orange-d1;
          color: $ssf-orange-d1;
          white-space: nowrap;
          &:hover {
            background: $ssf-orange-d1;
            color: #fff;
          }
          &.btn-unselect{
            color: #fff;
            background-color: #807f7f;
            border-color: #807f7f;
          }
        }
      }
    }
  }

  .survey-container{
    position: relative;
    margin: auto !important;

    .survey-wrapper{
      padding: 10px;
    }
  }
}

.media-mycard-desc {

  .sponsor-logo-placeholder {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .label-value {
    font-size: 12px !important;
    color: #55a9e8;
    font-weight: bold;
  }
}

.filter-items{
  line-height: 1;
  font-size: 12px;
  padding: 2px 6px;
  margin: 0 5px 10px;
  position: relative;
  font-weight: 600;
  border-radius: 15px;
  border: 1px solid #dbdbdb;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  & .fa-times {
    font-size: 10px;
    transition: all .2s;

    &:hover {
      cursor: pointer;
      transform: scale(1.3);
    }
  }
}
// mobile screen share icons
@media only screen and (max-width: 768px){
  .pageContainer{
    .topSectionWrapper{
      .tab-universe-container{
        .share-icon-div{
          margin-top: 7px;
        }
      }
      // .badgeWrapper{
      //   .badge-wrapper{
      //     .search-label{
      //       display: flex;
      //       .share-icon-div{
      //         display: flex;
      //         position: absolute;
      //         right: 14px;
      //       }
      //     }
      //   }
      // }
    }
  }
}
