.marquee {
  margin: 0 auto;
  white-space: nowrap;
  overflow: hidden;
}

.marquee a, .marquee div {
  display: inline-block;
  padding-left: 100% !important;
  -webkit-animation: marquee 300s linear infinite;
  animation: marquee 300s linear infinite;
}

/* Make it move */
@-webkit-keyframes marquee {
  0%   { transform: translate(0, 0); }
  100% { transform: translate(-100%, 0); }
}
@keyframes marquee {
  0%   { transform: translate(0, 0); }
  100% { transform: translate(-100%, 0); }
}

.marquee *:hover {
  -moz-animation-play-state: paused;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}
.marquee span {
  cursor: pointer;
  text-transform: uppercase;
  margin: 0 8px !important;
  font-family: $font-four !important;
}

@keyframes marquee2 {
  0%   { transform: translate(0,0);
         font-style:italic;
         opacity:0;}
  5%   { opacity:0;}
  14%  { opacity:0.8;}
  15%  { transform: translate(-46%, 0);
         opacity:1;
         font-style:normal;}
  85%  { transform: translate(-54%, 0);
         opacity:1;
         font-style:normal;}
  86%  { opacity:0.8;}
  96%  { opacity:0;}
  100% { transform: translate(-100%, 0);
         opacity:0;}}
