@import "./colors";
@import "./header";
@import "./responsive";

$c5 : #f7f7f7;

.ant-tabs-tab-prev, .ant-tabs-tab-next {
  z-index: 0 !important;
}

.grey-bg {
  background-color: $tag-grey-bg-color !important;
  border: 1px solid $tag-grey-bg-color !important;
}

.blue-bg {
  background-color: $tag-blue-bg-color !important;
  border: 1px solid $tag-blue-bg-color !important;
}

.ant-dropdown-menu-submenu-title {
  display: flex;
  align-items: center;
}

.ant-dropdown-menu-submenu-arrow {
  display: flex;
  align-items: center;

  .anticon-right {
    margin-right: 0 !important;
  }
}

.ant-layout {
  background: #f7f7f7 !important;
}

.web-dashboard-content-wrapper {
  height: calc(100vh - 60px);
  overflow-y: auto;
  background: #f7f7f7 !important;
  position: relative;
  margin-top: 0rem !important;

  background: #F9FAFC !important;
  background: $c5 !important;
  &::-webkit-scrollbar {
    width: 4px !important;
    border-radius: 6px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    width: 4px !important;
    border-radius: 6px;
    background-color: $in-active-color ;
  }
}

.rejection_alert.tab-content{
  .tab-pane{
    padding: 10px;
  }
}

.pos-relative {
  position: relative;
}

.filter-holder {
  display: flex;
  justify-content: flex-end;

  .export {
    margin-right: 10px;
    user-select: none;
  }
}

.restrict-switch-wrapper {
  .ant-switch {
    // background: #52c41a !important;
  }
  .ant-switch-checked {
    background: #ff4d4f !important;
  }
}

.api-response-notification-class {
  .ant-notification-notice-content {
    .ant-notification-notice-message {
      display: inline-block !important;
    }
  }
}
