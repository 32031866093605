@import "./range";
@import "./reset";

$column-width: 480px;
$gutter-width: 20px;

.app {
  margin: auto;
  // font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 300;
  text-align: center;
}

.section {
  display: inline-block;
  max-width: $column-width;
  margin: $gutter-width;
  text-align: left;
  vertical-align: top;
}

.player-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
}

.react-player {
  width: 100%;
  position: relative;
  height: 100%;
}

.faded {
  color: rgba(0, 0, 0, .5);
}

.footer {
  margin: $gutter-width;
}

.playerWrapper {
  width: 100%;
  height: 100%;
  position: relative;
  // border-radius: 10px;
  overflow: hidden;
  margin-bottom: 10px;
  &:hover .videoControls {
    display: block;
  }
}

.reactPlayer {
  width: 100%;
  position: relative;
  height: 100%;
  video {
    object-fit: cover;
  }
}

.videoplayerContainer {
  width: 100%;
  width: 452px;
  height: 100%;
  position: relative;
  // padding: 10px;
}
.videoplayerWrapper {
  width: 100%;
  height: 100%;
}

.videoControls {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  color: #eee;
  display: none;
  background: #1212138c;
  i {
    position: absolute;
    cursor: pointer;
  }
  .playPause {
    position: absolute;
    top: auto;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    font-size: 50px;
    height: 100%;
    vertical-align: 0;
  }
  .fullscreen {
    position: absolute;
    right: 5px;
    top: 5px;
    font-size: 20px;
  }
  .volume-controls {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 99;
    width: 33px;
    height: 100px;
    .volume-icon {
      bottom: 12px;
      left: 7px;
      cursor: pointer;
    }
    .volume-slider {
      position: absolute;
      bottom: 26px;
      height: 80px;
      margin-bottom: 6px;
      .ant-slider-rail {
        width: 2px;
      }
      .ant-slider-track {
        width: 2px;
      }
      .ant-slider-step {
        width: 2px;
      }
      .ant-slider-handle {
        width: 12px;
        height: 12px;
      }
    }
  }
}

.trackBarContainer {
  position: absolute;
  width: 100%;
  bottom: 20px;

  .inputSeekbar {
    position: absolute;
    bottom: -10px;
    z-index: 9;
    width: 100%;
    opacity: 0;
    padding: 0 30px;
    cursor: pointer;
  }

  .playedbar {
    width: 100%;
    position: absolute;
    z-index: 6;
    opacity: 0.89;
    padding: 0 30px;
    border-radius: 10px;
    overflow: hidden;

    progress::-webkit-progress-value {
      border-radius: 12px;
      background: #ff0000;
    }
  }
  .loadedbar {
    width: 100%;
    position: absolute;
    z-index: 5;
    padding: 0 30px;
    border-radius: 10px;
    overflow: hidden;

    progress::-webkit-progress-value {
      border-radius: 12px;
      background: black;
    }

  }

  progress {
    display: block; /* default: inline-block */
    width: 100%;
    height: 3px;
    border: 0 none;
    background: #fff;
    border-radius: 14px;
  }

  progress::-moz-progress-bar {
    border-radius: 12px;
    background: transparent;
  }

  progress::-webkit-progress-bar {
    background: transparent;
  }

  progress::-webkit-progress-bar {
    border-radius: 12px;
    background: transparent;
  }
}
.videoTitleText {
  font-size: 14px;
  line-height: 2;
  letter-spacing: 0.5px;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.linkText {
  font-size: 14px;
  letter-spacing: 0.5px;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  span {
    padding-right: 10px;
  }
}
