.ssf-modal {
  // font-family: 'Roboto-Medium';
}

.ssf-modal .modal-content {
  background-color: #e6e6e6;
  border-radius: 0;
  border: 0;

  @media (max-width: 1024px) {
    width: 75%;
  }
  @media (max-width: 767px) {
    width: 100%;
  }
}

.ssf-modal__header {
  background-color: #353a3e;
  color: #ffffff;
  font-family: 'Roboto';
  justify-content: center;
  font-weight:  $font-weight-bold;
  border-radius: 0;
  border-bottom: 0;
  height: 40px;
  padding: 10px;

  .modal-title {
    font-size: 16px;
    font-weight: $font-weight-bold;
    display: block;
    width: 100%;
    text-align: center;
  }
}

.ssf-modal__close-btn {
  float: right;
  cursor: pointer;
}

.ssf-modal__footer {
  height: 60px;
  background-color: #f7f7f7;
}

/////////////////////////////////////////////////////////////////////////////////



.registrations-process-modal-backdrop {
  display: block !important;
}

.registrations-process-modal {
  .modal-content {
    border: 0;
    border-radius: 15px;
    background-color: #56a9e8;
  }
  .modal-body {
    padding: 3.5rem;
  }
}

.fs-1 {
  font-size: 1rem !important;
}

.text-align-center {
  text-align: center !important;
}

.text-align-left {
  text-align: left !important;
}

.justify-content-left {
  justify-content: left !important;
}

.registrations-process-modal-container {
  .registrations-completed-close {
    position: absolute;
    right: 1rem;
    top: 1rem;
    font-size: 1.5rem;
    color: #fff;
    cursor: pointer;
  }

  .feature-close-icon {
    position: relative;
    bottom: 5.875rem;
    left: 25.4375rem !important;
    font-size: 1.5rem;
    color:#fff;
    cursor: pointer;
    opacity: 0.7;
  }

  .registrations-completed-check {
    font-size: 6rem;
    text-align: center;
    color: #fff;
  }

  .header-wrapper {
    h1 {
      font-size: 2rem !important;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.31;
      letter-spacing: normal;
      color: #ffffff;
    }
    h2 {
      font-size: 13px !important;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.38;
      letter-spacing: normal;
      color: #ffffff;
    }
  }
  .content-wrapper {
    font-size: 13px;
    font-weight: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
    margin-bottom: 1.5rem;
  }
  .cta-wrapper {
    flex-direction: row;
    align-items: normal;
    .btn {
      width: 100%;
      font-weight: normal;
      text-transform: capitalize;
      border: 1px solid #fff;

      &:first-child {
        margin-right: 20px;
      }
    }
  }
}

@include respond(phone){
  .registrations-process-modal {
    .modal-content {

    }
    .modal-body {
      padding: 1rem;
    }
  }

  .registrations-process-modal-container {
    .registrations-completed-close {

    }

    .registrations-completed-check {

    }

    .header-wrapper {
      h1 {
        font-size: 2rem !important;
      }
      h2 {
        font-size: 13px !important;

      }
    }
    .content-wrapper {

    }
    .cta-wrapper {
      .btn {
        line-height: 1.2;

        &:first-child {
          margin-right: 0.8rem;
        }
      }
    }
  }
}

@include respond(tab){
  .registrations-process-modal-container {
    .feature-close-icon{
      left: 25.4375rem !important;
    }
  }
}
