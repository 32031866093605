.profile-container {
  font-family: 'Roboto';
  margin-bottom: 20px;
}

.profile {
  padding-bottom: 40px;
}

.profile__section {
  background: #ffffff;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
  max-width: 546px;
  margin: 0 auto;
  &.top {
    border-radius: 15px 15px 0px 0px;
  }
  &.bottom {
    border-radius: 0px 0px 15px 15px;
    padding-bottom: 15px;
  }
}

.profile__alert {
  max-width: 546px;
  margin: 0 auto;
}

.profile__user-info {
  padding: 15px 0px 10px;

  .form-group {
    margin-bottom: 5px;
    color: #202529;

    label {
      font-size: 14px;
    }
    input {
      font-size: 14px;
    }

    .field-value {
      font-weight: $font-weight-medium;
      font-size: 16px;
      color: #202529;
    }
  }
}

.change-password-form {
  font-weight: $font-weight-medium;
  width: 280px;
  
  .form-group {
    margin-bottom: 10px;
    color: #202529;

    label {
      font-size: 14px;
    }
    input {
      font-size: 14px;
    }

    .secondary-btn {
      padding: 6px 15px !important;
      font-size: 12px !important;
      border-radius: 30px;
      line-height: 1.4;
      background: #fff !important;
      color:  #56a9e8 !important;
      height: 30px !important;
      border-color: #56a9e8 !important;
      transition: all 0.3s;
      &:hover {
        background: #56a9e8 !important;
        color: #fff !important;
      }
      &:focus {
        box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25) !important;
      }
    }
  }
}

.change-password__footer {
  display: flex;
  justify-content: space-between;
  padding: 15px 15px 0 15px;
  margin-top: 10px;
  border-top: 1px solid #cdd4db;
}

.profile-editable-form {
  font-weight: $font-weight-medium;
  font-size: 14px;
  width: 280px;

  .form-group {
    margin-bottom: 10px;
    color: #202529;

    label {
      font-size: 14px;
      margin-bottom: 2px;
      padding-left: 2px;
    }
    input {
      font-size: 14px;
    }
  }
}

.profile-view-form {
  font-weight: $font-weight-medium;
  font-size: 14px;

  .form-group {
    margin-bottom: 10px;

    label {
      font-size: 14px;
      margin-bottom: 2px;
      padding-left: 2px;
      color: #878787;
    }

    .field-value {
      font-size: 16px;
      color: #202529;
      padding-left: 2px;
    }
  }
}

.profile__section-header {
  border-bottom: 1px solid #cdd4db;
  margin-bottom: 15px;
  padding-top: 15px;
}

.profile__section-title {
  font-weight: 600 !important;
  font-size: 14px !important;
  color: #878787;
  padding-bottom: 15px;
  margin: 0px;
  display: inline-flex;
  text-transform: uppercase;
}

.profile__section-title-required {
  font-weight: $font-weight-medium;
  padding-top: 14px;
  display: inline-flex;
  float: right;
  font-style: italic;
  color: #55a9e8;
  font-size: 10px!important;
}

.profile__section-title-required-label {
  font-weight: $font-weight-medium;
  display: inline-flex;
  font-style: italic;
  color: #55a9e8;
}

.profile__section-link {
  padding-top: 14px;
}

.profile__section-footer {
  display: flex;
  align-items: center;
  border-top: 1px solid #cdd4db;
  min-height: 60px;
  margin-left: -15px;
  margin-right: -15px;

  .form-group{
    margin: auto;
    
    .secondary-btn {
      padding: 6px 15px !important;
      font-size: 12px !important;
      border-radius: 30px;
      line-height: 1.4;
      background: #fff !important;
      color: #56a9e8 !important;
      height: 30px !important;
      border-color: #56a9e8 !important;
      transition: all 0.3s;
      &:hover {
        background: #56a9e8 !important;
        color: #fff !important;
      }
      &:focus {
        box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25) !important;
      }
    }
  }
}

.profile-details-wrapper{
  .profile-element-wrapper{
    border-bottom: 1px solid #cdd4db;
    padding-bottom: 15px;
    margin-bottom: 15px;
    &:last-child {
      padding-bottom: 0px;
      margin-bottom: 0px;
      border-bottom: none;
    }
  }
}

.profile-description-heading {
  h4,h5{
    display: inline-flex;
  }
  p,a{
    display: inline-flex;
    float: right;
  }
}

.profile-description-heading,
.profile-change-plan { 
  h5{
    font-size: 14px !important;
    font-weight: 600 !important;
  }
}

.profile-boxed-details{
  padding: 15px;
  border: 1px solid #cdd4db;
  border-radius: 5px;
}

.profile-change-plan{
  h6{
    font-size: 17px !important;
    display: inline-flex;
    margin-bottom: 0px;
  }
  p{
    margin-bottom: 0px;
  }
  .basic-section,
  .premium-section {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}

.profile__subscription-button {
  .form-group{
    margin: auto;    
    .secondary-btn {
      padding: 6px 15px !important;
      font-size: 12px !important;
      border-radius: 30px;
      line-height: 1.4;
      background: #fff !important;
      color: #56a9e8 !important;
      height: 30px !important;
      border-color: #56a9e8 !important;
      transition: all 0.3s;
      @media only screen and (max-width: 760px) {
        font-size: 9px !important;
      }
      &:hover {
        background: #56a9e8 !important;
        color: #fff !important;
      }
      &:focus {
        box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25) !important;
      }
    }
  }
}
$secondary-blue: #1e5f91;

.profile-description-list{
  font-size: 12px;
  margin: 0px;
}

.profile-description-list,
.premium-feature-list {
  ul {
    margin: 0px;
    padding-left: 20px;
    list-style: none;
    li {      
      list-style: disc;
      color: #1e5f91;
      line-height: 2.08;
      font-size: 12px;
      p {
        display: inline-block;
        color: #8d8d8d;
        vertical-align: top;
        line-height: 2.08;
      }
      b {
        font-weight: 600!important;
      }
    }
  }
}

.profile-plan-price{
  margin: 0px 0px 20px 0px;
  p{
    font-size: 12px;
  }
  b{
    font-size: 15px;
  }
  .profile-mute-text{
    font-size: 11px;
  }
}

.premium-feature-list{
  p{
    font-size: 12px;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0px;
    }  
  }  
}

.profile-border-right{
  border-right: 1px solid #eaeaea;
}

.profile-mute-text{
  font-size: 10px;
  color: #8d8d8d;
  font-family: 'Open Sans';
}

.pcm__description p{
  margin-bottom: 0px;
}

.pcm__button-group{
  margin-top: 20px !important;
  .btn{
    min-width: 100px;
    margin-right: 10px;
  }
  .primary-btn {
    padding: 6px 15px !important;
    font-size: 12px !important;
    border-radius: 30px;
    line-height: 1.4;
    background: #56a9e8 !important;
    color: #fff !important;
    height: 30px !important;
    border-color: #56a9e8 !important;
    transition: all 0.3s;
    &:hover {
      background: #fff !important;
      color: #56a9e8 !important;
    }
    &:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25) !important;
    }
  }
}

.profile-plan-details{
  margin-top: 10px;
  h6{
    font-size: 14px !important;
  }
  p{
    margin-bottom: 0px;
  }
}

.profile-card-details{
  margin-top: 10px;
  h6,p{
    display: inline-flex;
  }
  p{
    float: right;
  }
}

.profile-billing-address{
  margin-top: 10px;
}

.profile-billing-check{
  h5, p, .ant-checkbox-wrapper{
    display: inline-flex;
  }
  .ant-checkbox-wrapper{
    float: right;
  }
  .ant-checkbox-inner{
    width: 25px;
    height: 25px;
    &::after{
      top: 40%;
      left: 20%;
      width: 8.428571px;
      height: 18.28571428px;
      border-color: #ffffff !important;
    }
  }
  .ant-checkbox-checked{
    .ant-checkbox-inner{
      background-color: #1890ff !important;
      border-color: #1890ff !important;
      &::after{
        border-color: #ffffff !important;
      }
    }
  }
}

.mt-10{
  margin-top: 10px;
}

.newProfile{
  .ant-modal-content{
    border-radius: 30px;
  }
  .ant-notification-notice-message{
    display: none;
  }
  .invalid-feedback{
    color: #a5685f!important;
  }
  .ant-select-selection-search{
    right: 0!important;
    input{
      text-align: right;
    }
  }
  .ant-select-dropdown{
    border-radius: 10px;
  }
}
