.broker__link-container {
  background: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  margin-bottom: 10px;
  border-radius: 15px;
}

.broker__list {
  margin: 0;

  .broker__list-item {
    background: #ffffff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    margin-bottom: 10px;
  }

  .broker__list-header {
    display: flex;
    padding: 5px;
    min-height: 87px;

    .broker__bars {
      margin-right: 10px;
    }

    .broker__header-logo {
      position: relative;
      display: flex;
      flex: 1 1 30%;
      justify-content: flex-start;
      align-items: center;
      margin-right: 10px;
      padding: 15px;
      background: #f9f9f9;
      img {
        border: 1px solid #cdd4db;
      }

      .broker_cred{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }

    .broker__header-value {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: #f9f9f9;
      flex: 1 1 50%;
      .broker__header-value-item {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
  }

  .broker__account-label {
    font-weight: $font-weight-bold;
    color: #333333;
    font-size: 16px;
    text-align: center;
  }

  .broker__account-value {
    font-weight: $font-weight-medium;
    color: #878787;
    font-size: 14px;
    text-align: center;
  }

  .broker__currency-label {
    font-weight: $font-weight-medium;
    color: #878787;
    font-size: 14px;
    text-align: center;
  }

  .broker__default-label {
    font-weight: $font-weight-medium;
    color: #878787;
    font-size: 12px;
  }

  .broker__currency-value {
    font-weight: $font-weight-bold;
    color: #333333;
    font-size: 16px;
    text-align: center;
  }

  .broker__acc-list {
    list-style-type: none;
    margin: 0;
    padding: 5px 0;
    .broker__acc-list-item {
      margin: 5px;
      padding: 5px;
      background: #f9f9f9;
    }

    .broker__acc-list-cell {
      display: flex;
      flex-direction: column;
      flex: 1;
    }
    .broker__acc-list-item-setdefault {
      display: flex;
      align-items: center;
    }

    .broker__acc-list-item-acc-info {
      flex: 1;
    }

    .broker__acc-list-item-delete {
      max-width: 50px;
      align-items: center;
      .btn-delete-orange {
        color: #e26b02;
      }
    }

    .broker__acc-list-item-wrapper {
      display: flex;
      align-items: center;
      min-height: 87px;
    }
  }
}

.broker__accounts-container {
  margin-top: 10px;
  background: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

.broker__toggle-icon {
  padding-left: 10px;
  padding-right: 10px;
  color: #f36300;
}

.broker__link {
  display: flex;
  align-items: center;
  padding: 9px;
  color: #878787;
  font-family: $font-weight-medium;
  font-size: 14px;

  &:hover {
    color: #564f4f;
  }
  .coming-soon-img {
    margin-left: 170px;
    transform: scale(2.5) rotate(5deg);
  }
}

.broker__add-icon {
  font-size: 24px;
  padding-right: 10px;
  color: #f36300;
}

.brokers-modal {
  .ssf-modal__body {
    /*min-height: 250px;*/
  }
  @media (max-width: 1024px){
    .modal-content {
      width: auto !important;
    }
  }
  @media (width: 768px){
    .modal-content{
    width: auto;
    }
  }
  @media (max-width: 700px){
    .modal-content{
    width: 75% !important;
    }
  }
}

.brokers__wrapper {
  display: flex;
  justify-content: center;
}

.brokers {
  display: flex;
  flex-flow: row wrap;
  list-style-type: none;
  padding: 0;
  max-width: 458px;
}

.brokers__item {
  padding: 10px 8px;
  .brokers__buttonWrap {
    width: 136px;
    padding: 5px;
    color: #fff;
    background-color: #fff;
    border-radius: 30px;
    border: 1px solid fade($primary-color-hover, 20%);
    transition: all 0.3;
    .brokers__displayName {
      margin: 0;
      font-size: 15px;
      font-weight: 700;
      color: $primary-color-hover;
      line-height: 2.5;
      text-transform: capitalize;
      text-align: center;
      white-space: nowrap;
      vertical-align: initial;
    }
    &:hover, &:focus {
      border: 0 !important;
      box-shadow: unset !important;
      background-color: $primary-color-hover !important;
      .brokers__displayName {
        color: #fff !important;
      }
    }
  }
}

.brokers__img {
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
  background: #ffffff;
}

.brokers-signin-form {
  max-width: 200px;
  margin: 0 auto;
}

.broker-account-form {
  width: 376px;
  min-width: 376px;
  margin: 0 auto;
}

.account-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  overflow: auto;
}
.account-list-wrapper {
    padding-right: 10px;  // Breathing space between scroller and list item
    margin-top: -10px;
    overflow: hidden;
    max-height: 300px;
    position: relative;
}

.account-list__item {
  background: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  min-height: 79px;
  padding: 5px;
  margin: 10px 0;
}

.account-list__item-wrapper {
  background: #f9f9f9;
}

.account-list__name {
  padding: 0;
  font-weight: $font-weight-bold;
  font-size: 14px;
  color: #333333;
  margin: 0;
  margin-left: -15px;
}

.account-list__name--disabled {
  opacity: 0.5;
}

.account-list__checkbox {
  font-size: 16px;
}

.account-info {
  margin: 0;
  .account-info__head {
    font-weight: $font-weight-medium;
    font-size: 12px;
    color: #878787;
  }
  .account-info__head-cell {
    padding: 0 5px 0 5px;
  }

  .account-info__body-cell {
    padding: 0px 5px 5px 5px;
    font-weight: $font-weight-bold;
    font-size: 14px;
    color: #333333;
  }
}

.hamburger-menu {
  list-style-type: none;
  margin: -0.5rem -0.75rem; //Override popover paddings
  padding: 0;
  min-width: 214px;
}

.hamburger__item {
  font-family: $font-weight-regular;
  font-size: 16px;
  color: #202529;
  border-bottom: 1px solid rgba(205, 212, 219, 0.5);
  padding: 10px;
  cursor: pointer;

  .hamburger__link {
    color: #202529;
    padding: 10px;
    width: 100%;
    display: inline-block;

    &:hover {
      color: #202529;
    }
  }
}

.hamburger__item-link {
  padding: 0;
}

.delete-account-confirm {
  .modal-content {
    min-height: 150px;
  }
}

.trade-it-radio {
  margin-left: 0px;
  position: static;
}
