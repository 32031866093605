.alert-enter {
  opacity: 0.01;
  transform: translateX(100%);
}
.alert-enter-active {
  opacity: 1;
  transform: translateX(0%);
  transition: all 500ms ease-in;
}
.alert-exit {
  opacity: 1;
  transform: translateX(0%);
}
.alert-exit-active {
  opacity: 0.01;
  transform: translateX(-100%);
  transition: all 500ms ease-out;
}

.example-enter {
  opacity: 0.01;
}

.example-enter.example-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.example-leave {
  opacity: 1;
}

.example-leave.example-leave-active {
  opacity: 0.01;
  transition: opacity 500ms ease-in;
}

.fade-enter {
  opacity: 0.01;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0.01;
  transition: opacity 500ms ease-in;
}

.slide-enter {
  opacity: 0.01;
  transform: translateX(400px);
}
.slide-enter-active {
  opacity: 1;
  transform: translateX(0%);
  transition: all 500ms ease-out;
}
.slide-exit {
  opacity: 1;
}
.slide-exit-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}


.tease {
  -webkit-animation: tease .4s ease .2s 2 alternate;
  animation: tease .4s ease .2s 2 alternate;
}
@keyframes tease {
  0%   { transform: translate(0, 0); }
  100% { transform: translate(0, 8px); }
}

@keyframes notify{
  0% { opacity: 0; bottom: 0; }
  100% { opacity: 1; bottom: 25px; }
}
