.container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  margin-top: 2rem;
  margin: 2rem;
}

.search {
  width: 50%;
  border: 1px solid #5fb0ea;
  border-radius: 1rem;
  padding: 4px;
  padding-left: 1rem;
}

table {
  width: 95%;
  border-collapse: collapse;
}

th,
td {
  border: 1px solid #ccc;
  padding: 1rem;
  text-align: left;
}

th {
  background-color: #f4f4f4;
}

.searchUser {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;
  gap: 10px;
}

.searchBtn {
  padding: 5px 10px;
  background-color: #5fb0ea;
  color: white;
  border: none;
  border-radius: 10px;
  font-weight: 600;
}
