/*** Form Override  ****/
.profile .form-control {
  height: 44px;
}

/*** Form control Validation  ****/

.invalid-feedback {
  font-size: 14px;
}

/*** Button Override ****/
.form-group {
  .btn {
    height: 44px;
  }
}
