@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?qsv0ew');
  src:  url('fonts/icomoon.eot?qsv0ew#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?qsv0ew') format('truetype'),
    url('fonts/icomoon.woff?qsv0ew') format('woff'),
    url('fonts/icomoon.svg?qsv0ew#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-cancel:before {
  content: "\e91e";
}
.icon-Slader-Risk-Copy-4 .path1:before {
  content: "\e900";
  color: rgb(255, 255, 255);
}
.icon-Slader-Risk-Copy-4 .path2:before {
  content: "\e901";
  margin-left: -1em;
  color: rgb(250, 120, 5);
}
.icon-Slader-Risk-Copy-4 .path3:before {
  content: "\e902";
  margin-left: -1em;
  color: rgb(250, 120, 5);
}
.icon-Slader-Risk-Copy-3:before {
  content: "\e903";
}
.icon-Slader-Risk-Copy-2:before {
  content: "\e904";
  color: #fff;
}
.icon-Slader-Risk-Copy .path1:before {
  content: "\e905";
  color: rgb(255, 255, 255);
}
.icon-Slader-Risk-Copy .path2:before {
  content: "\e906";
  margin-left: -1em;
  color: rgb(13, 15, 54);
}
.icon-Slader-Risk-Copy .path3:before {
  content: "\e907";
  margin-left: -1em;
  color: rgb(13, 15, 54);
}
.icon-Slader-Risk .path1:before {
  content: "\e908";
  color: rgb(255, 255, 255);
}
.icon-Slader-Risk .path2:before {
  content: "\e909";
  margin-left: -1em;
  color: rgb(229, 229, 229);
}
.icon-Slader-Risk .path3:before {
  content: "\e90a";
  margin-left: -1em;
  color: rgb(229, 229, 229);
}
.icon-Slader-Return-Copy-2 .path1:before {
  content: "\e90b";
  color: rgb(255, 255, 255);
}
.icon-Slader-Return-Copy-2 .path2:before {
  content: "\e90c";
  margin-left: -1em;
  color: rgb(13, 15, 54);
}
.icon-Slader-Return-Copy-2 .path3:before {
  content: "\e90d";
  margin-left: -1em;
  color: rgb(13, 15, 54);
}
.icon-Slader-Return-Copy .path1:before {
  content: "\e90e";
  color: rgb(255, 255, 255);
}
.icon-Slader-Return-Copy .path2:before {
  content: "\e90f";
  margin-left: -1em;
  color: rgb(250, 120, 5);
}
.icon-Slader-Return-Copy .path3:before {
  content: "\e910";
  margin-left: -1em;
  color: rgb(250, 120, 5);
}
.icon-Slader-Return .path1:before {
  content: "\e911";
  color: rgb(255, 255, 255);
}
.icon-Slader-Return .path2:before {
  content: "\e912";
  margin-left: -1em;
  color: rgb(229, 229, 229);
}
.icon-Slader-Return .path3:before {
  content: "\e913";
  margin-left: -1em;
  color: rgb(229, 229, 229);
}
.icon-Slader-Home-Active .path1:before {
  content: "\e914";
  color: rgb(255, 255, 255);
}
.icon-Slader-Home-Active .path2:before {
  content: "\e915";
  margin-left: -1em;
  color: rgb(250, 120, 5);
}
.icon-Slader-Home .path1:before {
  content: "\e916";
  color: rgb(255, 255, 255);
}
.icon-Slader-Home .path2:before {
  content: "\e917";
  margin-left: -1em;
  color: rgb(229, 229, 229);
}
.icon-Slader-Fees-Copy-2 .path1:before {
  content: "\e918";
  color: rgb(255, 255, 255);
}
.icon-Slader-Fees-Copy-2 .path2:before {
  content: "\e919";
  margin-left: -1em;
  color: rgb(250, 120, 5);
}
.icon-Slader-Fees-Copy .path1:before {
  content: "\e91a";
  color: rgb(255, 255, 255);
}
.icon-Slader-Fees-Copy .path2:before {
  content: "\e91b";
  margin-left: -1em;
  color: rgb(13, 15, 54);
}
.icon-Slader-Fees .path1:before {
  content: "\e91c";
  color: rgb(255, 255, 255);
}
.icon-Slader-Fees .path2:before {
  content: "\e91d";
  margin-left: -1em;
  color: rgb(229, 229, 229);
}
