.account-confirm-modal {
  width: 356px;
  height: 264px;

  .ssf-modal__body {
    text-align: center;
  }
}

.account-confirm-modal__caption {
  font-weight: $font-weight-medium;
  font-size: 15px;
  color: #333333;
  margin-bottom: 15px;
  line-height: 20px;
}

.agree-terms-modal{
  width: 500px;
}

.agree-terms-modal__desc {
  font-weight: $font-weight-normal;
  font-size: 15px;
  text-align: justify;
  word-wrap: break-word;
  color: #333333;
  margin-bottom: 20px;
  line-height: 20px;
  user-select: none;
  margin-left: 10px;
  margin-right: 10px;
}

.account-confirm-modal__footer {
  margin-left: -16px;
  margin-right: -16px;
  margin-bottom: -16px;
  
  .btn-group {
    width: 100%;
    button {
      border-radius: 0;
    }
  }
}

.account-confirm-modal__desc {
  font-weight: $font-weight-regular;
  font-size: 12px;
  color: #151515;
  line-height: 20px;
}
